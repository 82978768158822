import gql from 'graphql-tag';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const AdminOverviewFragmentDoc = gql`
    fragment adminOverview on Admin {
  adminId
  email
  phoneNumber
  firstName
  lastName
  created
  updated
  nameDisplay
}
    `;
export const AdminCreateAdminMutationResultFragmentDoc = gql`
    fragment adminCreateAdminMutationResult on Mutation {
  createAdmin(input: $input) {
    clientMutationId
    admin {
      ...adminOverview
    }
  }
}
    ${AdminOverviewFragmentDoc}`;
export const AppointmentCycleConfigFragmentDoc = gql`
    fragment appointmentCycleConfig on AppointmentCycleConfig {
  appointmentCycleConfigId
  practiceId
  departmentId
  providerId
  appointmentTypeId
  step
  config
  created
  updated
  priority
  estimatedAdjustByMax
  estimatedAdjustmentByPercent
  estimatedMax
  estimatedPercentage
}
    `;
export const AdminCreateAppointmentCycleConfigMutationResultFragmentDoc = gql`
    fragment adminCreateAppointmentCycleConfigMutationResult on Mutation {
  createAppointmentCycleConfig(input: $input) {
    clientMutationId
    appointmentCycleConfig {
      ...appointmentCycleConfig
    }
  }
}
    ${AppointmentCycleConfigFragmentDoc}`;
export const AppointmentCycleDefaultValueFragmentDoc = gql`
    fragment appointmentCycleDefaultValue on AppointmentCycleDefaultValue {
  appointmentCycleDefaultValueId
  practiceId
  departmentId
  providerId
  appointmentTypeId
  step
  minutesDuration
  created
  updated
  priority
}
    `;
export const AdminCreateAppointmentCycleDefaultValueMutationResultFragmentDoc = gql`
    fragment adminCreateAppointmentCycleDefaultValueMutationResult on Mutation {
  createAppointmentCycleDefaultValue(input: $input) {
    clientMutationId
    appointmentCycleDefaultValue {
      ...appointmentCycleDefaultValue
    }
  }
}
    ${AppointmentCycleDefaultValueFragmentDoc}`;
export const AdminCreateAppointmentTypeFavoriteMutationResultFragmentDoc = gql`
    fragment adminCreateAppointmentTypeFavoriteMutationResult on Mutation {
  createAdminFavoriteAppointmentType(input: $input) {
    admin {
      adminId
      adminFavoriteAppointmentTypes {
        nodes {
          adminId
          adminFavoriteAppointmentTypeId
          appointmentTypeId
          listIndex
        }
      }
    }
  }
}
    `;
export const AdminCreateDepartmentFavoriteMutationResultFragmentDoc = gql`
    fragment adminCreateDepartmentFavoriteMutationResult on Mutation {
  createAdminFavoriteDepartment(input: $input) {
    admin {
      adminId
      adminFavoriteDepartments {
        nodes {
          adminId
          adminFavoriteDepartmentId
          departmentId
          listIndex
        }
      }
    }
  }
}
    `;
export const DepartmentWorkingHourFragmentDoc = gql`
    fragment departmentWorkingHour on DepartmentWorkingHour {
  departmentWorkingHourId
  practiceId
  departmentId
  overrideDate
  dayOfWeek
  startOfDayMinutes
  endOfDayMinutes
}
    `;
export const AdminCreateDepartmentWorkingHourMutationResultFragmentDoc = gql`
    fragment adminCreateDepartmentWorkingHourMutationResult on Mutation {
  createDepartmentWorkingHour(input: $input) {
    clientMutationId
    departmentWorkingHour {
      ...departmentWorkingHour
    }
  }
}
    ${DepartmentWorkingHourFragmentDoc}`;
export const AdminCreateManagerMutationResultFragmentDoc = gql`
    fragment adminCreateManagerMutationResult on Mutation {
  createManager(input: $input) {
    clientMutationId
  }
}
    `;
export const ManagerProviderPermissionFragmentDoc = gql`
    fragment managerProviderPermission on ManagerProviderPermission {
  managerProviderPermissionId
  providerId
  managerId
  practiceId
  departmentId
  created
  updated
}
    `;
export const AdminCreateManagerProviderPermissionMutationResultFragmentDoc = gql`
    fragment AdminCreateManagerProviderPermissionMutationResult on Mutation {
  createManagerProviderPermission(input: $input) {
    clientMutationId
    managerProviderPermission {
      ...managerProviderPermission
    }
  }
}
    ${ManagerProviderPermissionFragmentDoc}`;
export const AdminCreatePracticeFavoriteMutationResultFragmentDoc = gql`
    fragment adminCreatePracticeFavoriteMutationResult on Mutation {
  createAdminFavoritePractice(input: $input) {
    admin {
      adminId
      adminFavoritePractices {
        nodes {
          adminId
          adminFavoritePracticeId
          practiceId
          listIndex
        }
      }
    }
  }
}
    `;
export const PracticeSlugFragmentDoc = gql`
    fragment practiceSlug on PracticeSlug {
  practiceSlugId
  practiceId
  practiceName
  slug
  managerDashboardUrl
  patientDashboardUrl
  receptionStatusDashboardUrl
}
    `;
export const AdminCreatePracticeSlugMutationResultFragmentDoc = gql`
    fragment adminCreatePracticeSlugMutationResult on Mutation {
  createPracticeSlug(input: $input) {
    practiceSlug {
      ...practiceSlug
      practice {
        practiceId
        practiceSlugs {
          nodes {
            ...practiceSlug
          }
        }
      }
    }
  }
}
    ${PracticeSlugFragmentDoc}`;
export const HubspotContactFragmentDoc = gql`
    fragment hubspotContact on HubspotContact {
  email
  firstname
  lastname
  phone
  hs_object_id
}
    `;
export const HubspotSubscriptionFragmentDoc = gql`
    fragment hubspotSubscription on HubspotSubscription {
  hs_createdate
  hs_lastmodifieddate
  hs_next_payment_due_date
  hs_recurring_billing_start_date
  hs_object_id
  hs_status
  last_fetched
}
    `;
export const HubspotLineItemFragmentDoc = gql`
    fragment hubspotLineItem on HubspotLineItem {
  name
  description
  quantity
  hs_sku
  hs_object_id
}
    `;
export const PracticeSubscriptionFragmentDoc = gql`
    fragment practiceSubscription on PracticeSubscription {
  practiceSubscriptionId
  practiceId
  created
  updated
  hubspotSubscriptionId
  hubspotSubscriptionData
  hubspotSubscription {
    contacts {
      ...hubspotContact
    }
    subscription {
      ...hubspotSubscription
    }
    lineItems {
      ...hubspotLineItem
    }
  }
}
    ${HubspotContactFragmentDoc}
${HubspotSubscriptionFragmentDoc}
${HubspotLineItemFragmentDoc}`;
export const AdminCreatePracticeSubscriptionMutationResultFragmentDoc = gql`
    fragment adminCreatePracticeSubscriptionMutationResult on Mutation {
  createPracticeSubscription(input: $input) {
    practiceSubscription {
      ...practiceSubscription
      practice {
        practiceId
        practiceSubscriptions {
          nodes {
            ...practiceSubscription
          }
        }
      }
    }
  }
}
    ${PracticeSubscriptionFragmentDoc}`;
export const PracticeTableauReportFragmentDoc = gql`
    fragment practiceTableauReport on PracticeTableauReport {
  practiceTableauReportId
  practiceId
  title
  slug
  timePublished
  url
  created
  updated
}
    `;
export const AdminCreatePracticeTableauReportMutationResultFragmentDoc = gql`
    fragment adminCreatePracticeTableauReportMutationResult on Mutation {
  createPracticeTableauReport(input: $input) {
    practiceTableauReport {
      ...practiceTableauReport
      practice {
        practiceId
        practiceTableauReports {
          nodes {
            ...practiceTableauReport
          }
        }
      }
    }
  }
}
    ${PracticeTableauReportFragmentDoc}`;
export const ProviderDashboardWarningFragmentDoc = gql`
    fragment providerDashboardWarning on ProviderDashboardWarning {
  providerDashboardWarningId
  practiceId
  practice {
    practiceId
    name
  }
  providerId
  provider {
    providerId
    displayName
  }
  managerSiteMessage
  rssSiteMessage
  hideRssSiteDelays
  created
  updated
}
    `;
export const AdminCreateProviderDashboardWarningMutationResultFragmentDoc = gql`
    fragment adminCreateProviderDashboardWarningMutationResult on Mutation {
  createProviderDashboardWarning(input: $input) {
    clientMutationId
    providerDashboardWarning {
      ...providerDashboardWarning
    }
  }
}
    ${ProviderDashboardWarningFragmentDoc}`;
export const ProviderDepartmentOptionNoIdFragmentDoc = gql`
    fragment providerDepartmentOptionNoId on ProviderDepartmentOption {
  providerId
  practiceId
  departmentId
  appointmentTypeId
  showCheckInButtonInVwr
  showSuggestedArrivalTimeInVwr
  anchorAutoPauseToSuggestedArrivalTime
  patientSiteShowCallButton
  patientSiteShowIntakeForm
  patientSiteShowLocation
  patientSiteIntakeFormLink
  patientSiteContactTranslationId
  patientSiteDirectionsTranslationId
  appointmentPlaceholderMessageTranslationId
  appointmentScheduledMessageTranslationId
  appointmentReminderMessageTranslationId
  appointmentDelayedMessageTranslationId
  appointmentRoomReadyMessageTranslationId
  patientSiteRemoteDirectionsTranslationId
  patientSiteFaqTranslationId
  patientSiteIntakeFormUrlTranslationId
  googlePlaceId
  suggestedArrivalTimeMaxDriftMinutes
  patientSiteDisplayPaymentPortal
  patientSiteDisplayPatientPortal
  appointmentsHideOnStatus
  appointmentsHideOnManager
  appointmentsDisableDelayCalc
  patientSiteDisplayLatestSmsSuggestedArrivalTime
  patientSitePracticeSlugId
  autoPauseAfterMinutes
  forcePauseAfterMinutes
  minMinutesBetweenReminderTexts
  patientSiteAboutVideoLinkTranslationId
  useRenderingProvider
  statsType
  persistCheckInToEhr
  useStatsV2
  useMLDurations
  excludeFromViewsAndQueues
  appointmentsAutoTextingDisabled
  noSmsBeforeMinutesIntoDay
  pollPatientChartsOnWaitForIntakeVitals
  pollPatientChartsOnWaitForIntakeAllergies
  pollPatientChartsOnWaitForIntakeEncounterReasons
  pollPatientChartsOnWaitForIntakeProblems
  pollPatientChartsOnWaitForDoctorHpi
  pollPatientChartsOnWaitForDoctorOrders
  pollPatientChartsOnWaitForDoctorEncounterReasons
  pollPatientChartsOnWaitForDoctorReviewOfSystem
  pollPatientChartsOnExamDiagnosis
  canRollbackStatus
  useBetaOverbookedStackingBehavior
  useStatusInferenceByLocation
}
    `;
export const ProviderDepartmentOptionFragmentDoc = gql`
    fragment providerDepartmentOption on ProviderDepartmentOption {
  providerDepartmentOptionId
  ...providerDepartmentOptionNoId
}
    ${ProviderDepartmentOptionNoIdFragmentDoc}`;
export const AdminCreateProviderDepartmentOptionMutationResultFragmentDoc = gql`
    fragment adminCreateProviderDepartmentOptionMutationResult on Mutation {
  createProviderDepartmentOption(input: $input) {
    clientMutationId
    providerDepartmentOption {
      ...providerDepartmentOption
    }
  }
}
    ${ProviderDepartmentOptionFragmentDoc}`;
export const ProviderDepartmentPaidConversionFragmentDoc = gql`
    fragment providerDepartmentPaidConversion on ProviderDepartmentPaidConversion {
  providerDepartmentPaidConversionId
  practiceId
  providerId
  departmentId
  dateTrialStart
  dateTrialEnd
  datePaidStart
  datePaidEnd
  created
  updated
}
    `;
export const AdminCreateProviderDepartmentPaidConversionMutationResultFragmentDoc = gql`
    fragment adminCreateProviderDepartmentPaidConversionMutationResult on Mutation {
  createProviderDepartmentPaidConversion(input: $input) {
    clientMutationId
    providerDepartmentPaidConversion {
      ...providerDepartmentPaidConversion
    }
  }
}
    ${ProviderDepartmentPaidConversionFragmentDoc}`;
export const AdminCreateProviderFavoriteMutationResultFragmentDoc = gql`
    fragment adminCreateProviderFavoriteMutationResult on Mutation {
  createAdminFavoriteProvider(input: $input) {
    admin {
      adminId
      adminFavoriteProviders {
        nodes {
          adminId
          adminFavoriteProviderId
          providerId
          listIndex
        }
      }
    }
  }
}
    `;
export const TemplateFragmentDoc = gql`
    fragment template on Template {
  templateId
  templateType
  name
  template
  created
  updated
}
    `;
export const AdminCreateTemplateMutationResultFragmentDoc = gql`
    fragment adminCreateTemplateMutationResult on Mutation {
  createTemplate(input: $input) {
    template {
      ...template
    }
  }
}
    ${TemplateFragmentDoc}`;
export const TranslationFragmentDoc = gql`
    fragment translation on Translation {
  translationId
  overrideTranslationId
  practiceId
  name
  spa
  eng
  translationType
  created
  updated
}
    `;
export const TranslationContentFragmentDoc = gql`
    fragment translationContent on TranslationContent {
  translationId
  content
  languageId
}
    `;
export const EnglishTranslationContentsFragmentDoc = gql`
    fragment englishTranslationContents on Translation {
  englishTranslationContents: translationContents(
    filter: {languageId: {in: ["eng"]}}
  ) {
    nodes {
      ...translationContent
    }
  }
}
    ${TranslationContentFragmentDoc}`;
export const NonEnglishTranslationContentsFragmentDoc = gql`
    fragment nonEnglishTranslationContents on Translation {
  nonEnglishTranslationContents: translationContents(
    filter: {languageId: {notIn: ["eng"]}}
  ) {
    nodes {
      ...translationContent
    }
  }
}
    ${TranslationContentFragmentDoc}`;
export const TranslationDetailFragmentDoc = gql`
    fragment translationDetail on Translation {
  ...translation
  ...englishTranslationContents
  ...nonEnglishTranslationContents
}
    ${TranslationFragmentDoc}
${EnglishTranslationContentsFragmentDoc}
${NonEnglishTranslationContentsFragmentDoc}`;
export const AdminCreateTranslationContentMutationResultFragmentDoc = gql`
    fragment adminCreateTranslationContentMutationResult on Mutation {
  createTranslationContent(input: $input) {
    clientMutationId
    query {
      translation(translationId: $translationId) {
        ...translationDetail
      }
    }
  }
}
    ${TranslationDetailFragmentDoc}`;
export const AdminCreateTranslationMutationResultFragmentDoc = gql`
    fragment adminCreateTranslationMutationResult on Mutation {
  createTranslation(input: $input) {
    translation {
      ...translation
    }
  }
}
    ${TranslationFragmentDoc}`;
export const AdminDeleteAppointmentCycleConfigMutationResultFragmentDoc = gql`
    fragment adminDeleteAppointmentCycleConfigMutationResult on Mutation {
  deleteAppointmentCycleConfig(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeleteAppointmentCycleDefaultValueMutationResultFragmentDoc = gql`
    fragment adminDeleteAppointmentCycleDefaultValueMutationResult on Mutation {
  deleteAppointmentCycleDefaultValue(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeleteAppointmentTypeFavoriteMutationResultFragmentDoc = gql`
    fragment adminDeleteAppointmentTypeFavoriteMutationResult on Mutation {
  deleteAdminFavoriteAppointmentTypeByAppointmentTypeIdAndAdminId(input: $input) {
    admin {
      adminId
      adminFavoriteAppointmentTypes {
        nodes {
          adminId
          adminFavoriteAppointmentTypeId
          appointmentTypeId
          listIndex
        }
      }
    }
  }
}
    `;
export const AdminDeleteDemoDataMutationResultFragmentDoc = gql`
    fragment adminDeleteDemoDataMutationResult on Mutation {
  deleteDemoData(input: $input) {
    ids
  }
}
    `;
export const AdminDeleteDepartmentFavoriteMutationResultFragmentDoc = gql`
    fragment adminDeleteDepartmentFavoriteMutationResult on Mutation {
  deleteAdminFavoriteDepartmentByDepartmentIdAndAdminId(input: $input) {
    admin {
      adminId
      adminFavoriteDepartments {
        nodes {
          adminId
          adminFavoriteDepartmentId
          departmentId
          listIndex
        }
      }
    }
  }
}
    `;
export const AdminDeleteDepartmentWorkingHourMutationResultFragmentDoc = gql`
    fragment adminDeleteDepartmentWorkingHourMutationResult on Mutation {
  deleteDepartmentWorkingHour(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeleteManagerProviderPermissionMutationResultFragmentDoc = gql`
    fragment adminDeleteManagerProviderPermissionMutationResult on Mutation {
  deleteManagerProviderPermission(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeletePracticeFavoriteMutationResultFragmentDoc = gql`
    fragment adminDeletePracticeFavoriteMutationResult on Mutation {
  deleteAdminFavoritePracticeByPracticeIdAndAdminId(input: $input) {
    admin {
      adminId
      adminFavoritePractices {
        nodes {
          adminId
          adminFavoritePracticeId
          practiceId
          listIndex
        }
      }
    }
  }
}
    `;
export const AdminDeletePracticeTableauReportMutationResultFragmentDoc = gql`
    fragment adminDeletePracticeTableauReportMutationResult on Mutation {
  deletePracticeTableauReport(input: $input) {
    clientMutationId
    query {
      practice(practiceId: $practiceId) {
        practiceId
        practiceTableauReports {
          nodes {
            ...practiceTableauReport
          }
        }
      }
    }
  }
}
    ${PracticeTableauReportFragmentDoc}`;
export const AdminDeleteProviderDashboardWarningMutationResultFragmentDoc = gql`
    fragment adminDeleteProviderDashboardWarningMutationResult on Mutation {
  deleteProviderDashboardWarning(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeleteProviderDepartmentOptionMutationResultFragmentDoc = gql`
    fragment adminDeleteProviderDepartmentOptionMutationResult on Mutation {
  deleteProviderDepartmentOption(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeleteProviderDepartmentPaidConversionMutationResultFragmentDoc = gql`
    fragment adminDeleteProviderDepartmentPaidConversionMutationResult on Mutation {
  deleteProviderDepartmentPaidConversion(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeleteProviderFavoriteMutationResultFragmentDoc = gql`
    fragment adminDeleteProviderFavoriteMutationResult on Mutation {
  deleteAdminFavoriteProviderByProviderIdAndAdminId(input: $input) {
    admin {
      adminId
      adminFavoriteProviders {
        nodes {
          adminId
          adminFavoriteProviderId
          providerId
          listIndex
        }
      }
    }
  }
}
    `;
export const AdminDeleteTemplateMutationResultFragmentDoc = gql`
    fragment adminDeleteTemplateMutationResult on Mutation {
  deleteTemplate(input: $input) {
    clientMutationId
  }
}
    `;
export const AdminDeleteTranslationContentMutationResultFragmentDoc = gql`
    fragment adminDeleteTranslationContentMutationResult on Mutation {
  deleteTranslationContentByTranslationIdAndLanguageId(input: $input) {
    query {
      translation(translationId: $translationId) {
        ...translationDetail
      }
    }
  }
}
    ${TranslationDetailFragmentDoc}`;
export const AdminDeleteTranslationMutationResultFragmentDoc = gql`
    fragment adminDeleteTranslationMutationResult on Mutation {
  deleteTranslation(input: $input) {
    clientMutationId
    query {
      translation(translationId: $translationId) {
        ...translationDetail
      }
    }
  }
}
    ${TranslationDetailFragmentDoc}`;
export const ManagerPasswordResetTokenFragmentDoc = gql`
    fragment managerPasswordResetToken on ManagerPasswordResetToken {
  managerPasswordResetTokenId
  token
  expiresAt
  usedAt
  managerId
}
    `;
export const AdminMakeManagerPasswordResetTokenMutationResultFragmentDoc = gql`
    fragment adminMakeManagerPasswordResetTokenMutationResult on Mutation {
  makeManagerPasswordResetToken(input: {managerId: $managerId}) {
    query {
      manager(managerId: $managerId) {
        managerId
        managerPasswordResetTokens {
          nodes {
            ...managerPasswordResetToken
          }
        }
      }
    }
  }
}
    ${ManagerPasswordResetTokenFragmentDoc}`;
export const AdminQueueAthenaPracticeRefreshAppointmentTypesMutationResultFragmentDoc = gql`
    fragment adminQueueAthenaPracticeRefreshAppointmentTypesMutationResult on Mutation {
  queueAthenaPracticeRefreshAppointmentTypes(input: $input) {
    ids
  }
}
    `;
export const AdminQueueAthenaPracticeSetUpMutationResultFragmentDoc = gql`
    fragment adminQueueAthenaPracticeSetUpMutationResult on Mutation {
  queueAthenaPracticeSetUp(input: $input) {
    ids
  }
}
    `;
export const AdminQueueGenerateDemoDataMutationResultFragmentDoc = gql`
    fragment adminQueueGenerateDemoDataMutationResult on Mutation {
  queueGenerateDemoData(input: $input) {
    ids
  }
}
    `;
export const AdminQueueProviderDayAppointmentFetchingMutationResultFragmentDoc = gql`
    fragment adminQueueProviderDayAppointmentFetchingMutationResult on Mutation {
  queueProviderDayAppointmentFetching(input: $input) {
    integer
  }
}
    `;
export const AdminQueueRefreshPracticeAppointmentTypeStatsMutationResultFragmentDoc = gql`
    fragment adminQueueRefreshPracticeAppointmentTypeStatsMutationResult on Mutation {
  queueRefreshPracticeAppointmentTypeStats(input: $input) {
    ids
  }
}
    `;
export const AdminQueueRestorePracticeDayFromS3MutationResultFragmentDoc = gql`
    fragment adminQueueRestorePracticeDayFromS3MutationResult on Mutation {
  queueRestorePracticeDayFromS3(input: $input) {
    integer
  }
}
    `;
export const AdminSetAdminPasswordMutationResultFragmentDoc = gql`
    fragment adminSetAdminPasswordMutationResult on Mutation {
  setAdminPassword(input: $input) {
    clientMutationId
  }
}
    `;
export const ActorOverviewFragmentDoc = gql`
    fragment actorOverview on Actor {
  actorId
  deletedAt
  timeLastActive
  adminDashboardSettings
  managerDashboardSettings
  managerAppointmentSortDirection
  managerNotifyTimeInReceptionDelays
  managerNotifyTimeInReceptionDelaysMethod
  managerNotifyTimeInReceptionDelaysAfterMinutes
  managerIgnoreTimeInReceptionEarlyCheckIn
  managerNotifyWaitForIntakeDelays
  managerNotifyWaitForIntakeDelaysMethod
  managerNotifyWaitForIntakeDelaysAfterMinutes
  managerNotifyWaitForDoctorDelays
  managerNotifyWaitForDoctorDelaysMethod
  managerNotifyWaitForDoctorDelaysAfterMinutes
  managerDisplayPatientInitialsOnly
  managerStatusSiteAutoSwipeDelaySeconds
  managerGroupAppointmentsByProvider
  managerDisplayArrowStateDurations
  managerNotifyCancellations
  managerDisplayCancellations
  managerDisplayCancellationsWithinHours
  managerDisplayPatientLocation
  managerDisplayIndicatorAfterPracticeNoShowCount
  managerDisplayIndicatorAfterPracticeSameDayCancellationCount
  managerTimeLastRequestedFeedback
  providerGroups {
    name
    providerIds
  }
}
    `;
export const AdminUpdateActorAdminDashboardSettingsMutationResultFragmentDoc = gql`
    fragment adminUpdateActorAdminDashboardSettingsMutationResult on Mutation {
  updateActor(
    input: {actorId: $actorId, patch: {adminDashboardSettings: $settings}}
  ) {
    actor {
      ...actorOverview
    }
  }
}
    ${ActorOverviewFragmentDoc}`;
export const AdminUpdateActorMutationResultFragmentDoc = gql`
    fragment adminUpdateActorMutationResult on Mutation {
  updateActor(input: {actorId: $actorId, patch: $patch}) {
    actor {
      ...actorOverview
    }
  }
}
    ${ActorOverviewFragmentDoc}`;
export const AdminDetailFragmentDoc = gql`
    fragment adminDetail on Admin {
  adminId
  adminActor {
    ...actorOverview
  }
  ...adminOverview
}
    ${ActorOverviewFragmentDoc}
${AdminOverviewFragmentDoc}`;
export const AdminUpdateAdminMutationResultFragmentDoc = gql`
    fragment adminUpdateAdminMutationResult on Mutation {
  updateAdmin(input: $input) {
    clientMutationId
    admin {
      ...adminDetail
    }
  }
}
    ${AdminDetailFragmentDoc}`;
export const AdminUpdateAppointmentCycleConfigMutationResultFragmentDoc = gql`
    fragment adminUpdateAppointmentCycleConfigMutationResult on Mutation {
  updateAppointmentCycleConfig(input: $input) {
    clientMutationId
    appointmentCycleConfig {
      ...appointmentCycleConfig
    }
  }
}
    ${AppointmentCycleConfigFragmentDoc}`;
export const AdminUpdateAppointmentCycleDefaultValueMutationResultFragmentDoc = gql`
    fragment adminUpdateAppointmentCycleDefaultValueMutationResult on Mutation {
  updateAppointmentCycleDefaultValue(input: $input) {
    clientMutationId
    appointmentCycleDefaultValue {
      ...appointmentCycleDefaultValue
    }
  }
}
    ${AppointmentCycleDefaultValueFragmentDoc}`;
export const AppointmentTypeOverviewFragmentDoc = gql`
    fragment appointmentTypeOverview on AppointmentType {
  appointmentTypeId
  srcType
  practiceId
  name
  nameShort
  nameDisplay
  duration
  estimatedDuration
  created
  updated
  remote
  intakeRequiresExamRoom
  examRequiresExamRoom
  multiDay
  appointmentPlaceholderMessageTranslationId
}
    `;
export const AppointmentTypePredictedDurationsFragmentDoc = gql`
    fragment appointmentTypePredictedDurations on AppointmentType {
  appointmentTypeId
  estimatedDuration
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForDoctorPredictedDuration
  waitForExamPredictedDuration: waitForDoctorPredictedDuration
  inExamPredictedDuration
  examPredictedDuration: inExamPredictedDuration
  waitForCheckoutPredictedDuration
  waitForCheckOutPredictedDuration: waitForCheckoutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const AppointmentTypeTemplatesTranslationsFragmentDoc = gql`
    fragment appointmentTypeTemplatesTranslations on AppointmentType {
  templateId
}
    `;
export const AdminAppointmentTypeDetailFragmentDoc = gql`
    fragment adminAppointmentTypeDetail on AppointmentType {
  ...appointmentTypeOverview
  ...appointmentTypePredictedDurations
  ...appointmentTypeTemplatesTranslations
  recentAppointmentCountsPerHours {
    aggregates {
      sum {
        count
      }
      distinctCount {
        departmentId
        providerId
      }
    }
  }
}
    ${AppointmentTypeOverviewFragmentDoc}
${AppointmentTypePredictedDurationsFragmentDoc}
${AppointmentTypeTemplatesTranslationsFragmentDoc}`;
export const AdminUpdateAppointmentTypeMutationResultFragmentDoc = gql`
    fragment adminUpdateAppointmentTypeMutationResult on Mutation {
  updateAppointmentType(
    input: {appointmentTypeId: $appointmentTypeId, patch: $patch}
  ) {
    appointmentType {
      ...adminAppointmentTypeDetail
    }
  }
}
    ${AdminAppointmentTypeDetailFragmentDoc}`;
export const GooglePlaceOverviewFragmentDoc = gql`
    fragment googlePlaceOverview on GooglePlace {
  googlePlaceId
  placeId
  name
  formattedAddress
  adrAddress
  types
  url
  utcOffset
  vicinity
  icon
  openingHours {
    periods {
      open {
        day
        time
      }
      close {
        day
        time
      }
    }
  }
  addressComponents {
    longName
    shortName
    types
  }
  geometry {
    location {
      lat
      lng
    }
    viewport {
      northeast {
        lat
        lng
      }
      southwest {
        lat
        lng
      }
    }
  }
}
    `;
export const AddressFragmentDoc = gql`
    fragment address on TtAddress {
  street1
  street2
  city
  state
  zip
  county
  country
  addressString
}
    `;
export const DepartmentOverviewFragmentDoc = gql`
    fragment departmentOverview on Department {
  departmentId
  practiceId
  name
  publicName
  timezone
  phoneNumber
  created
  updated
  srcType
  departmentWorkingHours(filter: {overrideDate: {isNull: true}}) {
    nodes {
      ...departmentWorkingHour
    }
  }
  googlePlace {
    ...googlePlaceOverview
  }
  address {
    ...address
  }
  srcType
}
    ${DepartmentWorkingHourFragmentDoc}
${GooglePlaceOverviewFragmentDoc}
${AddressFragmentDoc}`;
export const DepartmentsPracticePatientLocationFragmentDoc = gql`
    fragment departmentsPracticePatientLocation on DepartmentsPracticePatientLocation {
  departmentId
  practicePatientLocationId
}
    `;
export const DepartmentDetailFragmentDoc = gql`
    fragment departmentDetail on Department {
  ...departmentOverview
  departmentsPracticePatientLocations {
    nodes {
      ...departmentsPracticePatientLocation
    }
  }
  recentAppointmentCountsPerHours {
    aggregates {
      sum {
        count
      }
      distinctCount {
        appointmentTypeId
        providerId
      }
    }
  }
}
    ${DepartmentOverviewFragmentDoc}
${DepartmentsPracticePatientLocationFragmentDoc}`;
export const AdminUpdateDepartmentMutationResultFragmentDoc = gql`
    fragment adminUpdateDepartmentMutationResult on Mutation {
  updateDepartment(input: {departmentId: $departmentId, patch: $patch}) {
    department {
      ...departmentDetail
    }
  }
}
    ${DepartmentDetailFragmentDoc}`;
export const AdminUpdateDepartmentWorkingHourMutationResultFragmentDoc = gql`
    fragment adminUpdateDepartmentWorkingHourMutationResult on Mutation {
  updateDepartmentWorkingHour(input: $input) {
    clientMutationId
    departmentWorkingHour {
      ...departmentWorkingHour
    }
  }
}
    ${DepartmentWorkingHourFragmentDoc}`;
export const PhoneNumberFragmentDoc = gql`
    fragment phoneNumber on TtPhoneNumber {
  type
  number
}
    `;
export const PracticeOverviewFragmentDoc = gql`
    fragment practiceOverview on Practice {
  practiceId
  name
  description
  status
  ownerId
  phoneNumbers {
    ...phoneNumber
  }
  address {
    ...address
  }
  practicesAthena {
    athenaPracticeId
  }
  srcType
  waitingRoomSeats
  patientRoomCount
  employeeCount
  retainEstimationsForMinutes
  created
  timezone
  updated
  defaultDelayedMessageTranslationId
  defaultReminderMessageTranslationId
  defaultPlaceholderMessageTranslationId
  defaultRoomReadyMessageTranslationId
  defaultScheduledMessageTranslationId
  managerDashboardUrl
  patientDashboardUrl
  receptionStatusDashboardUrl
  slug
  includeIntoAppointmentsChangedFetch
  lookForOrphanAppointments
}
    ${PhoneNumberFragmentDoc}
${AddressFragmentDoc}`;
export const PracticeReportInputFragmentDoc = gql`
    fragment practiceReportInput on Practice {
  chartTimePerPatient
  lunchEndHour
  lunchStartHour
  lunchEndMinute
  lunchStartMinute
  lunchGap
  regularGap
}
    `;
export const PracticeDetailFragmentDoc = gql`
    fragment practiceDetail on Practice {
  ...practiceOverview
  ...practiceReportInput
  practiceSlugs {
    nodes {
      ...practiceSlug
    }
  }
}
    ${PracticeOverviewFragmentDoc}
${PracticeReportInputFragmentDoc}
${PracticeSlugFragmentDoc}`;
export const AdminUpdatePracticeMutationResultFragmentDoc = gql`
    fragment adminUpdatePracticeMutationResult on Mutation {
  updatePractice(input: {practiceId: $practiceId, patch: $patch}) {
    practice {
      ...practiceDetail
    }
  }
}
    ${PracticeDetailFragmentDoc}`;
export const PracticePatientLocationFragmentDoc = gql`
    fragment practicePatientLocation on PracticePatientLocation {
  practicePatientLocationId
  practiceId
  departmentId
  name
  usedForCheckIn
  usedForWaitForIntake
  usedForIntake
  usedForWaitForDoctor
  usedForExam
  usedForWaitForCheckout
  usedForCheckingOut
  defaultOnCheckIn
}
    `;
export const AdminUpdatePracticePatientLocationMutationResultFragmentDoc = gql`
    fragment adminUpdatePracticePatientLocationMutationResult on Mutation {
  updatePracticePatientLocation(
    input: {practicePatientLocationId: $practicePatientLocationId, patch: $patch}
  ) {
    practicePatientLocation {
      ...practicePatientLocation
      practice {
        practiceId
        practicePatientLocations {
          nodes {
            ...practicePatientLocation
          }
        }
      }
    }
  }
}
    ${PracticePatientLocationFragmentDoc}`;
export const AdminUpdatePracticeSlugMutationResultFragmentDoc = gql`
    fragment adminUpdatePracticeSlugMutationResult on Mutation {
  updatePracticeSlug(input: {practiceSlugId: $practiceSlugId, patch: $patch}) {
    practiceSlug {
      ...practiceSlug
      practice {
        practiceId
        practiceSlugs {
          nodes {
            ...practiceSlug
          }
        }
      }
    }
  }
}
    ${PracticeSlugFragmentDoc}`;
export const AdminUpdatePracticeTableauReportMutationResultFragmentDoc = gql`
    fragment adminUpdatePracticeTableauReportMutationResult on Mutation {
  updatePracticeTableauReport(
    input: {practiceTableauReportId: $practiceTableauReportId, patch: $patch}
  ) {
    practiceTableauReport {
      ...practiceTableauReport
      practice {
        practiceId
        practiceTableauReports {
          nodes {
            ...practiceTableauReport
          }
        }
      }
    }
  }
}
    ${PracticeTableauReportFragmentDoc}`;
export const AdminUpdateProviderDashboardWarningMutationResultFragmentDoc = gql`
    fragment adminUpdateProviderDashboardWarningMutationResult on Mutation {
  updateProviderDashboardWarning(input: $input) {
    clientMutationId
    providerDashboardWarning {
      ...providerDashboardWarning
    }
  }
}
    ${ProviderDashboardWarningFragmentDoc}`;
export const AdminUpdateProviderDepartmentOptionMutationResultFragmentDoc = gql`
    fragment adminUpdateProviderDepartmentOptionMutationResult on Mutation {
  updateProviderDepartmentOption(input: $input) {
    clientMutationId
    providerDepartmentOption {
      ...providerDepartmentOption
    }
  }
}
    ${ProviderDepartmentOptionFragmentDoc}`;
export const AdminUpdateProviderDepartmentPaidConversionMutationResultFragmentDoc = gql`
    fragment adminUpdateProviderDepartmentPaidConversionMutationResult on Mutation {
  updateProviderDepartmentPaidConversion(input: $input) {
    clientMutationId
    providerDepartmentPaidConversion {
      ...providerDepartmentPaidConversion
    }
  }
}
    ${ProviderDepartmentPaidConversionFragmentDoc}`;
export const ProviderOverviewFragmentDoc = gql`
    fragment providerOverview on Provider {
  providerId
  practiceId
  displayName
  examRoomCount
  fetchAppointmentsEnabled
  autoTextingEnabledAfter
  doctorCount
  firstName
  lastName
  sex
  username
  npi
  phoneNumber
  created
  updated
  srcType
  isTextReminderDisabledToday
  excludeFromAppointmentTypeCalculations
  timeOnboarded
}
    `;
export const AdminUpdateProviderMutationResultFragmentDoc = gql`
    fragment adminUpdateProviderMutationResult on Mutation {
  updateProvider(input: {providerId: $providerId, patch: $patch}) {
    provider {
      ...providerOverview
      __typename
    }
  }
}
    ${ProviderOverviewFragmentDoc}`;
export const AdminUpdateTemplateMutationResultFragmentDoc = gql`
    fragment adminUpdateTemplateMutationResult on Mutation {
  updateTemplate(input: $input) {
    template {
      ...template
    }
  }
}
    ${TemplateFragmentDoc}`;
export const AdminUpdateTranslationContentMutationResultFragmentDoc = gql`
    fragment adminUpdateTranslationContentMutationResult on Mutation {
  updateTranslationContentByTranslationIdAndLanguageId(input: $input) {
    translationContent {
      ...translationContent
    }
    query {
      translation(translationId: $translationId) {
        ...translationDetail
      }
    }
  }
}
    ${TranslationContentFragmentDoc}
${TranslationDetailFragmentDoc}`;
export const AdminUpdateTranslationMutationResultFragmentDoc = gql`
    fragment adminUpdateTranslationMutationResult on Mutation {
  updateTranslation(input: $input) {
    translation {
      ...translation
    }
  }
}
    ${TranslationFragmentDoc}`;
export const AdminAdminDetailQueryResultFragmentDoc = gql`
    fragment adminAdminDetailQueryResult on Query {
  admin(adminId: $adminId) {
    ...adminDetail
  }
}
    ${AdminDetailFragmentDoc}`;
export const AdminAdminsQueryResultFragmentDoc = gql`
    fragment adminAdminsQueryResult on Query {
  admins(orderBy: [LAST_NAME_ASC]) {
    nodes {
      ...adminOverview
    }
  }
}
    ${AdminOverviewFragmentDoc}`;
export const AppointmentEstimateFragmentDoc = gql`
    fragment appointmentEstimate on AppointmentEstimate {
  appointmentId
  estWaitForCheckoutTime
  estCheckoutTime
  estCheckInTime
  estWaitForIntakeTime
  estIntakeTime
  estWaitForDoctorTime
  estInExamTime
  estCheckoutEndTime
  suggestedArrivalTime
  oneHourPrevSuggestedArrivalTime
}
    `;
export const AppointmentOverviewSelfFragmentDoc = gql`
    fragment appointmentOverviewSelf on Appointment {
  appointmentId
  providerId
  scheduleProviderId
  renderingProviderId
  practiceId
  departmentId
  patientId
  appointmentTypeId
  srcType
  status
  appointmentTypeName
  patientAppointmentTypeName
  patientManualCheckIn
  timeScheduled
  timeCreated
  timeUpdated
  timeCheckIn
  timeCheckInStart
  timeCheckInEnd
  timeIntakeEnd
  timeIntakeEndDerived
  timeIntakeStart
  timeExamStart
  timeExamEnd
  timeExamEndCompiled
  timeCheckOutStart
  timeCheckOutEnd
  timeCancelled
  timeFirstTexted
  timeFirstTextedSuggestedArrivalTime
  timeLatestTexted
  timeLatestTextedSuggestedArrivalTime
  urgent
  cancelReasonId
  cancelledBy
  timeEncounterCheckedOut
  timeEnd
  timeReadyForProvider
  timeReadyForStaff
  pausedAt
  lastStateMachineStatusChange
  patientManualCheckIn
  roomReady
  duration
  autoPausedAt
  rescheduledAppointmentId
  confirmationStatusId
  confirmationStatusName
  created
  updated
  readyForCheckInBroadcastAt
  roomReadyButtonClickedAt
  timeDoneButtonPressed
  timeDrsLinkSent
  encounterId
  timeChartIntakeStart
  timeChartExamStart
  timeChartExamEnd
  timeIntakeEndDerived
  timeWaitForIntakeStartInferredFromLocation
  timeIntakeStartInferredFromLocation
  timeReadyForProviderInferredFromLocation
  timeExamStartInferredFromLocation
  timeWaitForCheckoutStartInferredFromLocation
  timeCheckOutStartInferredFromLocation
  timeCheckInStartFhirInferred
  timeIntakeStartFhirInferred
  timeIntakeEndFhirInferred
  timeExamStartFhirInferred
  timeExamEndFhirInferred
  timeCheckOutStartFhirInferred
  timeCheckInStartCompiled
  timeIntakeStartCompiled
  timeIntakeEndCompiled
  timeExamStartCompiled
  timeExamEndCompiled
  timeCheckedOutCompiled
  appointmentEstimate {
    ...appointmentEstimate
  }
  practicePatientLocation {
    ...practicePatientLocation
  }
  isDone
  isPaused
  isAutoPaused
  isInLagState
  isNotSeen
  isNotStarted
  isActualEndTimeIndeterminate
  isActualEndTimeDeterminate
  canBePaused
  calculatedAppointmentStatus
  statusString
  timelineIndex
  _timeCheckInStart
  _timeCheckInEnd
  _timeIntakeStart
  _timeIntakeEnd
  _timeExamStart
  _timeExamEnd
  _timeCheckOutStart
  _timeCheckOutEnd
}
    ${AppointmentEstimateFragmentDoc}
${PracticePatientLocationFragmentDoc}`;
export const AppointmentTextFragmentDoc = gql`
    fragment appointmentText on AppointmentText {
  appointmentTextId
  phoneNumber
  appointmentId
  twilioResponse
  created
  messageBody
}
    `;
export const AppointmentTokenSessionFragmentDoc = gql`
    fragment appointmentTokenSession on AppointmentTokenSession {
  appointmentId
  appointmentToken
  liveUrl
  timePatientSiteFirstVisited
  created
  updated
  expire
}
    `;
export const PatientScheduleProviderSameDayNoShowCountFragmentDoc = gql`
    fragment patientScheduleProviderSameDayNoShowCount on PatientScheduleProviderSameDayNoShowCount {
  practiceId
  patientId
  totalCount
  maxSameDayCancellationDateScheduled
  minSameDayCancellationDateScheduled
  sameDayCancellationCount
  maxNoShowDateScheduled
  minNoShowDateScheduled
  noShowCount
}
    `;
export const PatientOverviewFragmentDoc = gql`
    fragment patientOverview on Patient {
  patientId
  srcType
  firstName
  middleName
  lastName
  dob
  nameInitials
  nameDisplay
  firstNameDisplay
  fullNameDisplay
  ageInYears
  suffix
  preferredName
  sex
  consentToCall
  consentToText
  cannotSmsReason
  canSms
  emailAddresses
  languages
  created
  updated
  phoneNumbers {
    ...phoneNumber
  }
  address {
    ...address
  }
  patientScheduleProviderSameDayNoShowCounts {
    nodes {
      ...patientScheduleProviderSameDayNoShowCount
    }
  }
}
    ${PhoneNumberFragmentDoc}
${AddressFragmentDoc}
${PatientScheduleProviderSameDayNoShowCountFragmentDoc}`;
export const ProviderDepartmentOptionCompiledFragmentDoc = gql`
    fragment providerDepartmentOptionCompiled on ProviderDepartmentOptionsCompiled {
  providerId
  practiceId
  departmentId
  appointmentTypeId
  showCheckInButtonInVwr
  showSuggestedArrivalTimeInVwr
  anchorAutoPauseToSuggestedArrivalTime
  patientSiteShowCallButton
  patientSiteShowIntakeForm
  patientSiteShowLocation
  patientSiteIntakeFormLink
  patientSiteContactTranslationId
  patientSiteDirectionsTranslationId
  patientSiteRemoteDirectionsTranslationId
  appointmentPlaceholderMessageTranslationId
  appointmentScheduledMessageTranslationId
  appointmentReminderMessageTranslationId
  appointmentDelayedMessageTranslationId
  appointmentRoomReadyMessageTranslationId
  patientSiteFaqTranslationId
  patientSiteIntakeFormUrlTranslationId
  googlePlaceId
  suggestedArrivalTimeMaxDriftMinutes
  patientSiteDisplayPaymentPortal
  patientSiteDisplayPatientPortal
  appointmentsHideOnStatus
  appointmentsHideOnManager
  appointmentsDisableDelayCalc
  patientSiteDisplayLatestSmsSuggestedArrivalTime
  patientSitePracticeSlugId
  autoPauseAfterMinutes
  forcePauseAfterMinutes
  minMinutesBetweenReminderTexts
  patientSiteAboutVideoLinkTranslationId
  useRenderingProvider
  statsType
  persistCheckInToEhr
  useStatsV2
  useMLDurations
  excludeFromViewsAndQueues
  appointmentsAutoTextingDisabled
  noSmsBeforeMinutesIntoDay
  pollPatientChartsOnWaitForIntakeVitals
  pollPatientChartsOnWaitForIntakeAllergies
  pollPatientChartsOnWaitForIntakeEncounterReasons
  pollPatientChartsOnWaitForIntakeProblems
  pollPatientChartsOnWaitForDoctorHpi
  pollPatientChartsOnWaitForDoctorOrders
  pollPatientChartsOnWaitForDoctorEncounterReasons
  pollPatientChartsOnWaitForDoctorReviewOfSystem
  pollPatientChartsOnExamDiagnosis
  canRollbackStatus
  useBetaOverbookedStackingBehavior
  useStatusInferenceByLocation
}
    `;
export const AppointmentCycleConfigCompiledFragmentDoc = gql`
    fragment appointmentCycleConfigCompiled on AppointmentCycleConfigCompiled {
  practiceId
  departmentId
  appointmentTypeId
  providerId
  checkIn
  waitForIntake
  intake
  waitForExam
  exam
  waitForCheckout
  waitForCheckOut: waitForCheckout
  checkout
  checkOut: checkout
}
    `;
export const AppointmentTypeProviderStatFragmentDoc = gql`
    fragment appointmentTypeProviderStat on AppointmentTypesProviderStat {
  providerId
  appointmentTypeId
  estimatedDuration
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForDoctorPredictedDuration
  waitForExamPredictedDuration: waitForDoctorPredictedDuration
  inExamPredictedDuration
  examPredictedDuration: inExamPredictedDuration
  waitForCheckoutPredictedDuration
  waitForCheckOutPredictedDuration: waitForCheckoutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const StatsCompiledFragmentDoc = gql`
    fragment statsCompiled on StatsCompiled {
  intakePredictedDuration
  inExamPredictedDuration
  checkOutPredictedDuration
  checkInPredictedDuration
  waitForIntakePredictedDuration
  waitForDoctorPredictedDuration
  waitForCheckOutPredictedDuration
}
    `;
export const MlPredictedDurationsFragmentDoc = gql`
    fragment mlPredictedDurations on AppointmentPredictedDuration {
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForDoctorPredictedDuration
  inExamPredictedDuration
  waitForCheckOutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const AppointmentStatesFragmentDoc = gql`
    fragment appointmentStates on AppointmentStateInts {
  checkIn
  waitForIntake
  intake
  waitForExam
  exam
  waitForCheckout
  checkout
}
    `;
export const AppointmentOverviewFragmentDoc = gql`
    fragment appointmentOverview on Appointment {
  ...appointmentOverviewSelf
  nextAppointmentId
  nextAppointment {
    ...appointmentOverviewSelf
    provider {
      providerId
      displayName
    }
  }
  previousAppointmentId
  previousAppointment {
    ...appointmentOverviewSelf
    provider {
      providerId
      displayName
    }
  }
  hl7ProviderIdUsed
  appointmentTexts(orderBy: CREATED_ASC) {
    nodes {
      ...appointmentText
    }
  }
  appointmentTokenSession {
    ...appointmentTokenSession
  }
  patient {
    ...patientOverview
  }
  providerDepartmentOptionCompiled {
    ...providerDepartmentOptionCompiled
  }
  appointmentCycleConfigCompiled {
    ...appointmentCycleConfigCompiled
  }
  appointmentTypeProviderStat {
    ...appointmentTypeProviderStat
  }
  statsCompiled {
    ...statsCompiled
  }
  appointmentPredictedDuration {
    ...mlPredictedDurations
  }
  completedStateDurations {
    ...appointmentStates
  }
}
    ${AppointmentOverviewSelfFragmentDoc}
${AppointmentTextFragmentDoc}
${AppointmentTokenSessionFragmentDoc}
${PatientOverviewFragmentDoc}
${ProviderDepartmentOptionCompiledFragmentDoc}
${AppointmentCycleConfigCompiledFragmentDoc}
${AppointmentTypeProviderStatFragmentDoc}
${StatsCompiledFragmentDoc}
${MlPredictedDurationsFragmentDoc}
${AppointmentStatesFragmentDoc}`;
export const AdminAppointmentDayQueryResultFragmentDoc = gql`
    fragment adminAppointmentDayQueryResult on Query {
  appointments: adminProviderDayAppointments(
    practiceId: $practiceId
    providerIds: $providerIds
    dayStart: $dayStart
    dayEnd: $dayEnd
    filterStatuses: $filterStatuses
  ) {
    nodes {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const AdminAppointmentDayV2QueryResultFragmentDoc = gql`
    fragment adminAppointmentDayV2QueryResult on Query {
  appointments: adminProviderDayAppointmentsV2(
    practiceId: $practiceId
    providerIds: $providerIds
    dateScheduled: $dateScheduled
  ) {
    nodes {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const AppointmentCalculationsWithNowFragmentDoc = gql`
    fragment appointmentCalculationsWithNow on AppointmentCalculationsWithNow {
  appointmentId
  checkInDurationMinutes
  waitForIntakeDurationMinutes
  intakeDurationMinutes
  waitForExamDurationMinutes
  examDurationMinutes
  waitForCheckOutDurationMinutes
  checkOutDurationMinutes
}
    `;
export const AppointmentDurationsCompiledFragmentDoc = gql`
    fragment appointmentDurationsCompiled on AppointmentDurationsCompiled {
  appointmentId
  checkInPredictedDuration
  waitForIntakePredictedDuration
  intakePredictedDuration
  waitForExamPredictedDuration
  examPredictedDuration
  waitForCheckOutPredictedDuration
  checkOutPredictedDuration
}
    `;
export const AdminAppointmentDurationLogRatiosCompiledQueryResultFragmentDoc = gql`
    fragment adminAppointmentDurationLogRatiosCompiledQueryResult on Query {
  appointmentDurationLogRatiosCompileds(filter: $filter) {
    nodes {
      appointmentId
      greatestLogRatio
      checkInDurationRatio
      waitForIntakeDurationRatio
      intakeDurationRatio
      waitForExamDurationRatio
      examDurationRatio
      waitForCheckOutDurationRatio
      checkOutDurationRatio
      appointment {
        ...appointmentOverview
        provider {
          ...providerOverview
        }
        practice {
          ...practiceOverview
        }
        appointmentCalculationsWithNows {
          nodes {
            ...appointmentCalculationsWithNow
          }
        }
        appointmentDurationsCompileds {
          nodes {
            ...appointmentDurationsCompiled
          }
        }
      }
    }
  }
}
    ${AppointmentOverviewFragmentDoc}
${ProviderOverviewFragmentDoc}
${PracticeOverviewFragmentDoc}
${AppointmentCalculationsWithNowFragmentDoc}
${AppointmentDurationsCompiledFragmentDoc}`;
export const AdminAppointmentTypeDetailQueryResultFragmentDoc = gql`
    fragment adminAppointmentTypeDetailQueryResult on Query {
  appointmentType(appointmentTypeId: $appointmentTypeId) {
    ...adminAppointmentTypeDetail
  }
}
    ${AdminAppointmentTypeDetailFragmentDoc}`;
export const AdminAppointmentTypeProviderStatQueryResultFragmentDoc = gql`
    fragment adminAppointmentTypeProviderStatQueryResult on Query {
  appointmentTypesProviderStat(
    appointmentTypeId: $appointmentTypeId
    providerId: $providerId
  ) {
    ...appointmentTypeProviderStat
  }
}
    ${AppointmentTypeProviderStatFragmentDoc}`;
export const ServerInfoFragmentDoc = gql`
    fragment serverInfo on ServerInfo {
  name
  uptime
  isProd
  googleMapsApiKey
  hostname
  webhooksServerUrl
}
    `;
export const AdminBaseQueryResultFragmentDoc = gql`
    fragment adminBaseQueryResult on Query {
  practices(orderBy: [NAME_ASC]) {
    totalCount
    nodes {
      ...practiceOverview
    }
  }
  adminId: getSessionAdminId
  managerId: getSessionManagerId
  practiceId: getSessionPracticeId
  serverInfo {
    ...serverInfo
  }
}
    ${PracticeOverviewFragmentDoc}
${ServerInfoFragmentDoc}`;
export const AdminGetCompiledProviderDepartmentOptionQueryResultFragmentDoc = gql`
    fragment adminGetCompiledProviderDepartmentOptionQueryResult on Query {
  opt: getProviderDepartmentOptions(
    practiceId: $practiceId
    providerId: $providerId
    departmentId: $departmentId
    appointmentTypeId: $appointmentTypeId
  ) {
    ...providerDepartmentOption
  }
}
    ${ProviderDepartmentOptionFragmentDoc}`;
export const AdminGooglePlaceByIdQueryResultFragmentDoc = gql`
    fragment adminGooglePlaceByIdQueryResult on Query {
  googlePlaceById(placeId: $placeId) {
    ...googlePlaceOverview
  }
}
    ${GooglePlaceOverviewFragmentDoc}`;
export const AdminManagerPasswordResetTokensCardQueryResultFragmentDoc = gql`
    fragment adminManagerPasswordResetTokensCardQueryResult on Query {
  manager(managerId: $managerId) {
    managerId
    username
    managerPasswordResetTokens {
      nodes {
        ...managerPasswordResetToken
      }
    }
    practice {
      ...practiceOverview
    }
  }
}
    ${ManagerPasswordResetTokenFragmentDoc}
${PracticeOverviewFragmentDoc}`;
export const ProviderDepartmentAppointmentCountFragmentDoc = gql`
    fragment providerDepartmentAppointmentCount on ProviderDepartmentAppointmentCount {
  providerId
  departmentId
  status
  appointmentCount
  managerAppointmentCount
  practiceId
  dateScheduled
}
    `;
export const ProviderAppointmentsCountFragmentDoc = gql`
    fragment providerAppointmentsCount on Provider {
  providerId
  providerDepartmentAppointmentCounts(
    filter: {dateScheduled: {equalTo: $dayStart}, status: {notIn: ["CANCELED", "LEFT_WITHOUT_BEING_SEEN", "NO_SHOW"]}}
  ) {
    nodes {
      ...providerDepartmentAppointmentCount
    }
  }
}
    ${ProviderDepartmentAppointmentCountFragmentDoc}`;
export const ProviderDetailFragmentDoc = gql`
    fragment providerDetail on Provider {
  ...providerOverview
  ...providerAppointmentsCount
}
    ${ProviderOverviewFragmentDoc}
${ProviderAppointmentsCountFragmentDoc}`;
export const AdminProviderDetailFragmentDoc = gql`
    fragment adminProviderDetail on Provider {
  ...providerDetail
  ...providerAppointmentsCount
  managerProviderPermissions {
    aggregates {
      distinctCount {
        managerId
      }
    }
  }
  recentAppointmentCountsPerHours {
    aggregates {
      sum {
        count
      }
      distinctCount {
        appointmentTypeId
        departmentId
      }
    }
    groupedByApptType: groupedAggregates(groupBy: [APPOINTMENT_TYPE_ID]) {
      keys
      sum {
        count
      }
    }
    groupedByDepartment: groupedAggregates(groupBy: [DEPARTMENT_ID]) {
      keys
      sum {
        count
      }
    }
  }
  providerDepartmentPaidConversions {
    nodes {
      ...providerDepartmentPaidConversion
    }
  }
}
    ${ProviderDetailFragmentDoc}
${ProviderAppointmentsCountFragmentDoc}
${ProviderDepartmentPaidConversionFragmentDoc}`;
export const AdminPracticeBaseProvidersQueryResultFragmentDoc = gql`
    fragment adminPracticeBaseProvidersQueryResult on Query {
  practice(practiceId: $practiceId) {
    practiceId
    providers(orderBy: [LAST_NAME_ASC]) {
      totalCount
      nodes {
        ...adminProviderDetail
      }
    }
  }
}
    ${AdminProviderDetailFragmentDoc}`;
export const ManagerPermissionsFragmentDoc = gql`
    fragment managerPermissions on Manager {
  canAccessCheckoutTable
  canAccessReceptionStatusSite
  canAccessInsights
  canAccessMonthlyReport
  canAccessBilling
  canAccessPatientFlowReport
}
    `;
export const ManagerOverviewFragmentDoc = gql`
    fragment managerOverview on Manager {
  managerId
  practiceId
  email
  username
  firstName
  lastName
  phoneNumber
  sex
  eulaTimestamp
  nameDisplay
  isPracticeAdmin
  created
  updated
  dateTrialEnd
  tableauManagerId
  ...managerPermissions
  managerProviderPermissions {
    nodes {
      ...managerProviderPermission
    }
  }
  managerActor {
    ...actorOverview
  }
}
    ${ManagerPermissionsFragmentDoc}
${ManagerProviderPermissionFragmentDoc}
${ActorOverviewFragmentDoc}`;
export const AdminPracticeBaseQueryResultFragmentDoc = gql`
    fragment adminPracticeBaseQueryResult on Query {
  practice(practiceId: $practiceId) {
    ...practiceDetail
    owner {
      ...managerOverview
    }
    departments(orderBy: [PUBLIC_NAME_ASC]) {
      totalCount
      nodes {
        ...departmentDetail
      }
    }
    providers(orderBy: [LAST_NAME_ASC]) {
      totalCount
      nodes {
        ...adminProviderDetail
      }
    }
    appointmentTypes(orderBy: [NAME_ASC]) {
      totalCount
      nodes {
        ...adminAppointmentTypeDetail
      }
    }
    providerDepartmentOptions {
      totalCount
      nodes {
        ...providerDepartmentOption
      }
    }
  }
}
    ${PracticeDetailFragmentDoc}
${ManagerOverviewFragmentDoc}
${DepartmentDetailFragmentDoc}
${AdminProviderDetailFragmentDoc}
${AdminAppointmentTypeDetailFragmentDoc}
${ProviderDepartmentOptionFragmentDoc}`;
export const ManagerDetailFragmentDoc = gql`
    fragment managerDetail on Manager {
  ...managerOverview
}
    ${ManagerOverviewFragmentDoc}`;
export const AdminPracticeManagerDetailQueryResultFragmentDoc = gql`
    fragment adminPracticeManagerDetailQueryResult on Query {
  manager(managerId: $managerId) {
    ...managerDetail
    managerProviderPermissions {
      nodes {
        ...managerProviderPermission
      }
    }
  }
}
    ${ManagerDetailFragmentDoc}
${ManagerProviderPermissionFragmentDoc}`;
export const AdminPracticeManagersQueryResultFragmentDoc = gql`
    fragment adminPracticeManagersQueryResult on Query {
  practice(practiceId: $practiceId) {
    practiceId
    managers(orderBy: [LAST_NAME_ASC]) {
      totalCount
      nodes {
        ...managerDetail
        managerPasswordResetTokens {
          nodes {
            ...managerPasswordResetToken
          }
        }
        managerProviderPermissions {
          nodes {
            ...managerProviderPermission
          }
          aggregates {
            distinctCount {
              providerId
              departmentId
            }
          }
        }
      }
    }
  }
}
    ${ManagerDetailFragmentDoc}
${ManagerPasswordResetTokenFragmentDoc}
${ManagerProviderPermissionFragmentDoc}`;
export const AdminPracticePatientLocationsQueryResultFragmentDoc = gql`
    fragment adminPracticePatientLocationsQueryResult on Query {
  practice(practiceId: $practiceId) {
    practiceId
    practicePatientLocations(orderBy: [NAME_ASC]) {
      nodes {
        ...practicePatientLocation
      }
    }
  }
}
    ${PracticePatientLocationFragmentDoc}`;
export const AdminPracticeProviderDepartmentOptionsQueryResultFragmentDoc = gql`
    fragment adminPracticeProviderDepartmentOptionsQueryResult on Query {
  providerDepartmentOptions(condition: {practiceId: $practiceId}) {
    nodes {
      ...providerDepartmentOption
    }
  }
}
    ${ProviderDepartmentOptionFragmentDoc}`;
export const AdminProviderAppointmentDayFetchingQueryResultFragmentDoc = gql`
    fragment adminProviderAppointmentDayFetchingQueryResult on Query {
  providerAppointmentDaysToFetches(condition: {practiceId: $practiceId}) {
    nodes {
      providerId
      practiceId
      dayToFetch
      inProgress
    }
  }
}
    `;
export const AdminProviderDashboardWarningsQueryResultFragmentDoc = gql`
    fragment adminProviderDashboardWarningsQueryResult on Query {
  providerDashboardWarnings {
    nodes {
      ...providerDashboardWarning
    }
  }
}
    ${ProviderDashboardWarningFragmentDoc}`;
export const AppointmentDetailFragmentDoc = gql`
    fragment appointmentDetail on Appointment {
  ...appointmentOverview
  appointmentType {
    ...appointmentTypeOverview
  }
  provider {
    ...providerOverview
  }
  department {
    ...departmentOverview
  }
  practice {
    ...practiceOverview
  }
}
    ${AppointmentOverviewFragmentDoc}
${AppointmentTypeOverviewFragmentDoc}
${ProviderOverviewFragmentDoc}
${DepartmentOverviewFragmentDoc}
${PracticeOverviewFragmentDoc}`;
export const AppointmentTimeseriesStatsFragmentDoc = gql`
    fragment appointmentTimeseriesStats on Appointment {
  ...appointmentDetail
  appointmentEstimateSnapshots(orderBy: [CAPTURE_TIME_ASC]) {
    nodes {
      captureTime
      suggestedArrivalTime
      estIntakeTime
    }
  }
}
    ${AppointmentDetailFragmentDoc}`;
export const AdminProviderDayReportQueryResultFragmentDoc = gql`
    fragment adminProviderDayReportQueryResult on Query {
  provider(providerId: $providerId) {
    providerId
    appointments(
      orderBy: [TIME_SCHEDULED_ASC]
      filter: {timeScheduled: {greaterThanOrEqualTo: $startDate, lessThan: $endDate}}
    ) {
      nodes {
        ...appointmentOverview
        ...appointmentTimeseriesStats
      }
    }
  }
}
    ${AppointmentOverviewFragmentDoc}
${AppointmentTimeseriesStatsFragmentDoc}`;
export const AdminProviderDetailQueryResultFragmentDoc = gql`
    fragment adminProviderDetailQueryResult on Query {
  provider(providerId: $providerId) {
    ...adminProviderDetail
  }
}
    ${AdminProviderDetailFragmentDoc}`;
export const AdminSearchGooglePlaceQueryResultFragmentDoc = gql`
    fragment adminSearchGooglePlaceQueryResult on Query {
  searchGooglePlace(search: $search, type: $type) {
    candidates {
      place_id
    }
  }
}
    `;
export const AdminUpdateAppointmentMutationResultFragmentDoc = gql`
    fragment adminUpdateAppointmentMutationResult on Mutation {
  updateAppointment(input: {appointmentId: $appointmentId, patch: $patch}) {
    appointment {
      ...appointmentOverview
    }
  }
  queueRecalculateProviderDayAppts(input: {providerId: $providerId}) {
    ids
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const AuthTableauMutationResultFragmentDoc = gql`
    fragment authTableauMutationResult on Mutation {
  authTableau(email: $email) {
    jwt
  }
}
    `;
export const CreateProviderDepartmentAutoTextPausesMutationResultFragmentDoc = gql`
    fragment createProviderDepartmentAutoTextPausesMutationResult on Mutation {
  createProviderDepartmentAutoTextPauses(
    input: {providerIds: $providerIds, practiceId: $practiceId}
  ) {
    providerDepartmentAutoTextPauses {
      practiceId
      providerId
      pauseOnDate
    }
  }
}
    `;
export const PauseAppointmentMutationResultFragmentDoc = gql`
    fragment pauseAppointmentMutationResult on Mutation {
  updateAppointment(
    input: {appointmentId: $appointmentId, patch: {pausedAt: $pausedAt}}
  ) {
    appointment {
      appointmentId
      pausedAt
      canBePaused
      isPaused
      isAutoPaused
    }
  }
  queueRecalculateProviderDayAppts(input: {providerId: $providerId}) {
    ids
  }
}
    `;
export const QueueAppointmentSmsMutationResultFragmentDoc = gql`
    fragment queueAppointmentSmsMutationResult on Mutation {
  queueSendSms(input: $input) {
    ids
  }
}
    `;
export const QueueDemoAppointmentTextMutationResultFragmentDoc = gql`
    fragment queueDemoAppointmentTextMutationResult on Mutation {
  queueDemoAppointmentText(input: $input) {
    ids
  }
}
    `;
export const SetAppointmentDoneMutationResultFragmentDoc = gql`
    fragment setAppointmentDoneMutationResult on Mutation {
  updateAppointment(
    input: {appointmentId: $appointmentId, patch: {status: "CHECKED_OUT", timeEnd: $timeEnd, pausedAt: null}}
  ) {
    appointment {
      ...appointmentOverview
    }
  }
  queueRecalculateProviderDayAppts(input: {providerId: $providerId}) {
    ids
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const SetAppointmentReadyForCheckInMutationResultFragmentDoc = gql`
    fragment setAppointmentReadyForCheckInMutationResult on Mutation {
  queueReadyForCheckIn(input: $input) {
    ids
  }
  updateAppointment(
    input: {appointmentId: $appointmentId, patch: {roomReadyButtonClickedAt: $roomReadyButtonClickedAt}}
  ) {
    appointment {
      appointmentId
      roomReadyButtonClickedAt
    }
  }
}
    `;
export const UnpauseAppointmentMutationResultFragmentDoc = gql`
    fragment unpauseAppointmentMutationResult on Mutation {
  updateAppointment(
    input: {appointmentId: $appointmentId, patch: {pausedAt: null}}
  ) {
    appointment {
      appointmentId
      pausedAt
      canBePaused
      isPaused
      isAutoPaused
    }
  }
  queueRecalculateProviderDayAppts(input: {providerId: $providerId}) {
    ids
  }
}
    `;
export const UpdateActorManagerDashboardSettingsMutationResultFragmentDoc = gql`
    fragment updateActorManagerDashboardSettingsMutationResult on Mutation {
  updateActor(
    input: {actorId: $actorId, patch: {managerDashboardSettings: $settings}}
  ) {
    actor {
      ...actorOverview
    }
  }
}
    ${ActorOverviewFragmentDoc}`;
export const UpdateManagerMutationResultFragmentDoc = gql`
    fragment updateManagerMutationResult on Mutation {
  updateManager(input: {managerId: $managerId, patch: $patch}) {
    manager {
      ...managerDetail
    }
  }
}
    ${ManagerDetailFragmentDoc}`;
export const AdminFavoritesQueryResultFragmentDoc = gql`
    fragment adminFavoritesQueryResult on Query {
  adminFavoritePractices(condition: {adminId: $adminId}) {
    nodes {
      adminFavoritePracticeId
      practiceId
    }
  }
  adminFavoriteProviders(condition: {adminId: $adminId}) {
    nodes {
      adminFavoriteProviderId
      providerId
    }
  }
  adminFavoriteDepartments(condition: {adminId: $adminId}) {
    nodes {
      adminFavoriteDepartmentId
      departmentId
    }
  }
  adminFavoriteAppointmentTypes(condition: {adminId: $adminId}) {
    nodes {
      adminFavoriteAppointmentTypeId
      appointmentTypeId
    }
  }
}
    `;
export const AdminSmsTranslationDetailFragmentDoc = gql`
    fragment adminSmsTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const AdminSmsTranslationDetailQueryResultFragmentDoc = gql`
    fragment adminSmsTranslationDetailQueryResult on Query {
  translation(translationId: $translationId) {
    ...adminSmsTranslationDetail
  }
}
    ${AdminSmsTranslationDetailFragmentDoc}`;
export const LanguageFragmentDoc = gql`
    fragment language on Language {
  languageId
  iso6391Code
  englishName
  enabled
  favorited
}
    `;
export const AllLanguagesQueryResultFragmentDoc = gql`
    fragment allLanguagesQueryResult on Query {
  languages {
    nodes {
      ...language
    }
  }
}
    ${LanguageFragmentDoc}`;
export const AppointmentDetailQueryResultFragmentDoc = gql`
    fragment appointmentDetailQueryResult on Query {
  appointment(appointmentId: $appointmentId) {
    ...appointmentDetail
    patient {
      ...patientOverview
    }
    provider {
      ...providerOverview
    }
    appointmentType {
      ...appointmentTypeOverview
    }
    department {
      ...departmentOverview
    }
  }
}
    ${AppointmentDetailFragmentDoc}
${PatientOverviewFragmentDoc}
${ProviderOverviewFragmentDoc}
${AppointmentTypeOverviewFragmentDoc}
${DepartmentOverviewFragmentDoc}`;
export const AppointmentTypeOverviewSetQueryResultFragmentDoc = gql`
    fragment appointmentTypeOverviewSetQueryResult on Query {
  appointmentTypes(
    filter: {practiceId: {equalTo: $practiceId}, appointmentTypeId: {in: $appointmentTypeIds}}
  ) {
    nodes {
      ...appointmentTypeOverview
    }
  }
}
    ${AppointmentTypeOverviewFragmentDoc}`;
export const DepartmentDetailQueryResultFragmentDoc = gql`
    fragment departmentDetailQueryResult on Query {
  department(departmentId: $departmentId) {
    ...departmentDetail
    recentAppointmentCountsPerHours {
      groupedByApptType: groupedAggregates(groupBy: [APPOINTMENT_TYPE_ID]) {
        keys
        sum {
          count
        }
      }
      groupedByProvider: groupedAggregates(groupBy: [PROVIDER_ID]) {
        keys
        sum {
          count
        }
      }
      aggregates {
        sum {
          count
        }
        distinctCount {
          appointmentTypeId
          providerId
        }
      }
    }
  }
}
    ${DepartmentDetailFragmentDoc}`;
export const DepartmentOverviewSetQueryResultFragmentDoc = gql`
    fragment departmentOverviewSetQueryResult on Query {
  departments(
    filter: {practiceId: {equalTo: $practiceId}, departmentId: {in: $departmentIds}}
  ) {
    nodes {
      ...departmentOverview
    }
  }
}
    ${DepartmentOverviewFragmentDoc}`;
export const EnabledLanguagesQueryResultFragmentDoc = gql`
    fragment enabledLanguagesQueryResult on Query {
  languages(condition: {enabled: true}) {
    nodes {
      ...language
    }
  }
}
    ${LanguageFragmentDoc}`;
export const GlobalTemplatesQueryResultFragmentDoc = gql`
    fragment globalTemplatesQueryResult on Query {
  templates(condition: {practiceId: null}) {
    nodes {
      ...template
    }
  }
}
    ${TemplateFragmentDoc}`;
export const GlobalTranslationsQueryResultFragmentDoc = gql`
    fragment globalTranslationsQueryResult on Query {
  translations(condition: {practiceId: null}) {
    nodes {
      ...translationDetail
    }
  }
}
    ${TranslationDetailFragmentDoc}`;
export const ManagerAdminBaseQueryResultFragmentDoc = gql`
    fragment managerAdminBaseQueryResult on Query {
  admin(adminId: $adminId) {
    ...adminDetail
  }
}
    ${AdminDetailFragmentDoc}`;
export const ManagerAppointmentDayQueryResultFragmentDoc = gql`
    fragment managerAppointmentDayQueryResult on Query {
  appointments: providerDayAppointments(
    practiceId: $practiceId
    providerIds: $providerIds
    dayStart: $dayStart
    dayEnd: $dayEnd
    filterStatuses: $filterStatuses
    managerId: $managerId
  ) {
    nodes {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const TableauLinksFragmentDoc = gql`
    fragment tableauLinks on TableauLinks {
  homeEmbedUrl
  performanceEmbedUrl
  growthEmbedUrl
  deliveryEmbedUrl
  productivityEmbedUrl
  adherenceEmbedUrl
  providerSummaryEmbedUrl
  monthlyReportEmbedUrl
  patientFlowEmbedUrl
}
    `;
export const ManagerBaseQueryResultFragmentDoc = gql`
    fragment managerBaseQueryResult on Query {
  adminId: getSessionAdminId
  managerId: getSessionManagerId
  practiceId: getSessionPracticeId
  tableauLinks {
    ...tableauLinks
  }
  serverInfo {
    ...serverInfo
  }
}
    ${TableauLinksFragmentDoc}
${ServerInfoFragmentDoc}`;
export const ManagerBillingInfoQueryResultFragmentDoc = gql`
    fragment managerBillingInfoQueryResult on Query {
  practice(practiceId: $practiceId) {
    practiceId
    practiceSubscriptions {
      nodes {
        ...practiceSubscription
      }
    }
  }
}
    ${PracticeSubscriptionFragmentDoc}`;
export const ManagerCancelledAppointmentDayQueryResultFragmentDoc = gql`
    fragment managerCancelledAppointmentDayQueryResult on Query {
  cancelledAppointments: providerDayCancelledAppointments(
    practiceId: $practiceId
    providerIds: $providerIds
    dayStart: $dayStart
    dayEnd: $dayEnd
    managerId: $managerId
  ) {
    nodes {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const ManagerCancelledAppointmentDayV2QueryResultFragmentDoc = gql`
    fragment managerCancelledAppointmentDayV2QueryResult on Query {
  cancelledAppointments: providerDayCancelledAppointmentsV2(
    practiceId: $practiceId
    providerIds: $providerIds
    dateStart: $dateStart
    dateEnd: $dateEnd
    canceledWithinHours: $canceledWithinHours
    managerId: $managerId
  ) {
    nodes {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const ManagerOverviewQueryResultFragmentDoc = gql`
    fragment managerOverviewQueryResult on Query {
  manager(managerId: $managerId) {
    ...managerOverview
  }
}
    ${ManagerOverviewFragmentDoc}`;
export const ManagerPracticeBaseQueryResultFragmentDoc = gql`
    fragment managerPracticeBaseQueryResult on Query {
  practice(practiceId: $practiceId) {
    ...practiceOverview
  }
  providers(condition: {practiceId: $practiceId, fetchAppointmentsEnabled: true}) {
    nodes {
      ...providerDetail
      ...providerAppointmentsCount
    }
  }
  departments(condition: {practiceId: $practiceId}) {
    nodes {
      ...departmentOverview
    }
  }
}
    ${PracticeOverviewFragmentDoc}
${ProviderDetailFragmentDoc}
${ProviderAppointmentsCountFragmentDoc}
${DepartmentOverviewFragmentDoc}`;
export const ManagerPracticeManagersQueryResultFragmentDoc = gql`
    fragment managerPracticeManagersQueryResult on Query {
  practice(practiceId: $practiceId) {
    practiceId
    managers(orderBy: [LAST_NAME_ASC]) {
      totalCount
      nodes {
        ...managerDetail
        managerProviderPermissions {
          aggregates {
            distinctCount {
              providerId
              departmentId
            }
          }
        }
      }
    }
  }
}
    ${ManagerDetailFragmentDoc}`;
export const ManagerPracticeProviderCountsQueryResultFragmentDoc = gql`
    fragment managerPracticeProviderCountsQueryResult on Query {
  providers(
    filter: {practiceId: {equalTo: $practiceId}, providerId: {in: $providerIds}}
  ) {
    nodes {
      ...providerAppointmentsCount
    }
  }
}
    ${ProviderAppointmentsCountFragmentDoc}`;
export const PracticeAppointmentCycleConfigsQueryResultFragmentDoc = gql`
    fragment practiceAppointmentCycleConfigsQueryResult on Query {
  appointmentCycleConfigs(
    filter: {or: [{practiceId: {isNull: true}}, {practiceId: {equalTo: $practiceId}}]}
  ) {
    nodes {
      ...appointmentCycleConfig
    }
  }
}
    ${AppointmentCycleConfigFragmentDoc}`;
export const PracticeAppointmentCycleDefaultValuesQueryResultFragmentDoc = gql`
    fragment practiceAppointmentCycleDefaultValuesQueryResult on Query {
  appointmentCycleDefaultValues(
    filter: {or: [{practiceId: {isNull: true}}, {practiceId: {equalTo: $practiceId}}]}
  ) {
    nodes {
      ...appointmentCycleDefaultValue
    }
  }
}
    ${AppointmentCycleDefaultValueFragmentDoc}`;
export const PracticeOverviewQueryResultFragmentDoc = gql`
    fragment practiceOverviewQueryResult on Query {
  practice(practiceId: $practiceId) {
    ...practiceOverview
  }
}
    ${PracticeOverviewFragmentDoc}`;
export const PracticeReportsQueryResultFragmentDoc = gql`
    fragment practiceReportsQueryResult on Query {
  practice(practiceId: $practiceId) {
    practiceId
    practiceTableauReports {
      totalCount
      nodes {
        ...practiceTableauReport
      }
    }
  }
}
    ${PracticeTableauReportFragmentDoc}`;
export const PracticeTemplatesQueryResultFragmentDoc = gql`
    fragment practiceTemplatesQueryResult on Query {
  templates(condition: {practiceId: $practiceId}) {
    nodes {
      ...template
    }
  }
}
    ${TemplateFragmentDoc}`;
export const AdminFaqTranslationDetailFragmentDoc = gql`
    fragment adminFaqTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const AdminContactTranslationDetailFragmentDoc = gql`
    fragment adminContactTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const AdminDirectionsTranslationDetailFragmentDoc = gql`
    fragment adminDirectionsTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const GlobalTranslationsFragmentDoc = gql`
    fragment globalTranslations on Query {
  smsTranslations: translations(
    condition: {translationType: "SMS", practiceId: null}
  ) {
    nodes {
      ...adminSmsTranslationDetail
    }
  }
  faqTranslations: translations(
    condition: {translationType: "FAQ", practiceId: null}
  ) {
    nodes {
      ...adminFaqTranslationDetail
    }
  }
  contactTranslations: translations(
    condition: {translationType: "CONTACT", practiceId: null}
  ) {
    nodes {
      ...adminContactTranslationDetail
    }
  }
  directionsTranslations: translations(
    condition: {translationType: "DIRECTIONS", practiceId: null}
  ) {
    nodes {
      ...adminDirectionsTranslationDetail
    }
  }
  linkTranslations: translations(
    condition: {translationType: "LINK", practiceId: null}
  ) {
    nodes {
      ...adminDirectionsTranslationDetail
    }
  }
}
    ${AdminSmsTranslationDetailFragmentDoc}
${AdminFaqTranslationDetailFragmentDoc}
${AdminContactTranslationDetailFragmentDoc}
${AdminDirectionsTranslationDetailFragmentDoc}`;
export const AdminLinkTranslationDetailFragmentDoc = gql`
    fragment adminLinkTranslationDetail on Translation {
  ...translationDetail
}
    ${TranslationDetailFragmentDoc}`;
export const PracticeTranslationsFragmentDoc = gql`
    fragment practiceTranslations on Practice {
  practiceId
  smsTranslations: translations(condition: {translationType: "SMS"}) {
    nodes {
      ...adminSmsTranslationDetail
    }
  }
  faqTranslations: translations(condition: {translationType: "FAQ"}) {
    nodes {
      ...adminFaqTranslationDetail
    }
  }
  contactTranslations: translations(condition: {translationType: "CONTACT"}) {
    nodes {
      ...adminContactTranslationDetail
    }
  }
  directionsTranslations: translations(condition: {translationType: "DIRECTIONS"}) {
    nodes {
      ...adminDirectionsTranslationDetail
    }
  }
  linkTranslations: translations(condition: {translationType: "LINK"}) {
    nodes {
      ...adminLinkTranslationDetail
    }
  }
}
    ${AdminSmsTranslationDetailFragmentDoc}
${AdminFaqTranslationDetailFragmentDoc}
${AdminContactTranslationDetailFragmentDoc}
${AdminDirectionsTranslationDetailFragmentDoc}
${AdminLinkTranslationDetailFragmentDoc}`;
export const PracticeTranslationsQueryResultFragmentDoc = gql`
    fragment practiceTranslationsQueryResult on Query {
  ...globalTranslations
  practice(practiceId: $practiceId) {
    ...practiceTranslations
  }
  translations(condition: {practiceId: $practiceId}) {
    nodes {
      ...translationDetail
    }
  }
}
    ${GlobalTranslationsFragmentDoc}
${PracticeTranslationsFragmentDoc}
${TranslationDetailFragmentDoc}`;
export const ProviderAppointmentCountsQueryResultFragmentDoc = gql`
    fragment providerAppointmentCountsQueryResult on Query {
  provider(providerId: $providerId) {
    ...providerAppointmentsCount
  }
}
    ${ProviderAppointmentsCountFragmentDoc}`;
export const ProviderDayAppointmentsV2QueryResultFragmentDoc = gql`
    fragment providerDayAppointmentsV2QueryResult on Query {
  appointments: providerDayAppointmentsV2(
    practiceId: $practiceId
    providerIds: $providerIds
    dateScheduled: $dateScheduled
    managerId: $managerId
  ) {
    nodes {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const ProviderDepartmentAppointmentCountsQueryResultFragmentDoc = gql`
    fragment providerDepartmentAppointmentCountsQueryResult on Query {
  recentAppointmentCountsPerHours(condition: {practiceId: $practiceId}) {
    nodes {
      departmentId
      providerId
      count
      timeScheduledHour
    }
  }
}
    `;
export const ProviderOverviewSetQueryResultFragmentDoc = gql`
    fragment providerOverviewSetQueryResult on Query {
  providers(
    filter: {practiceId: {equalTo: $practiceId}, providerId: {in: $providerIds}}
  ) {
    nodes {
      ...providerOverview
    }
  }
}
    ${ProviderOverviewFragmentDoc}`;
export const TranslationDetailQueryResultFragmentDoc = gql`
    fragment translationDetailQueryResult on Query {
  translation(translationId: $translationId) {
    ...translationDetail
  }
}
    ${TranslationDetailFragmentDoc}`;
export const AppointmentCheckInsSubscriptionResultFragmentDoc = gql`
    fragment appointmentCheckInsSubscriptionResult on Subscription {
  appointmentCheckedIn(providerId: $providerId) {
    appointment {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const AppointmentEstimateCreatedSubscriptionResultFragmentDoc = gql`
    fragment appointmentEstimateCreatedSubscriptionResult on Subscription {
  appointmentEstimateCreated(providerId: $providerId) {
    appointment {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const AppointmentEstimateUpdatesSubscriptionResultFragmentDoc = gql`
    fragment appointmentEstimateUpdatesSubscriptionResult on Subscription {
  appointmentEstimateUpdated(providerId: $providerId) {
    appointmentEstimate {
      ...appointmentEstimate
    }
  }
}
    ${AppointmentEstimateFragmentDoc}`;
export const AppointmentUpdatesSubscriptionResultFragmentDoc = gql`
    fragment appointmentUpdatesSubscriptionResult on Subscription {
  appointmentUpdated(providerId: $providerId) {
    appointment {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const GlobalProviderDashboardWarningNotifierSubscriptionResultFragmentDoc = gql`
    fragment globalProviderDashboardWarningNotifierSubscriptionResult on Subscription {
  globalProviderDashboardWarning {
    event
  }
}
    `;
export const PracticeProviderDashboardWarningNotifierSubscriptionResultFragmentDoc = gql`
    fragment practiceProviderDashboardWarningNotifierSubscriptionResult on Subscription {
  practiceProviderDashboardWarning {
    event
  }
}
    `;
export const ProviderAppointmentCancelledSubscriptionResultFragmentDoc = gql`
    fragment providerAppointmentCancelledSubscriptionResult on Subscription {
  appointmentCancelled(providerId: $providerId) {
    appointment {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const ProviderAppointmentCreatedSubscriptionResultFragmentDoc = gql`
    fragment providerAppointmentCreatedSubscriptionResult on Subscription {
  appointmentCreated(providerId: $providerId) {
    appointment {
      ...appointmentOverview
    }
  }
}
    ${AppointmentOverviewFragmentDoc}`;
export const ProviderAppointmentExamDelaysSubscriptionResultFragmentDoc = gql`
    fragment providerAppointmentExamDelaysSubscriptionResult on Subscription {
  appointmentExamDelays(providerId: $providerId) {
    appointment {
      ...appointmentOverview
      patient {
        ...patientOverview
      }
      provider {
        ...providerOverview
      }
    }
  }
}
    ${AppointmentOverviewFragmentDoc}
${PatientOverviewFragmentDoc}
${ProviderOverviewFragmentDoc}`;
export const ProviderAppointmentIntakeDelaysSubscriptionResultFragmentDoc = gql`
    fragment providerAppointmentIntakeDelaysSubscriptionResult on Subscription {
  appointmentIntakeDelays(providerId: $providerId) {
    appointment {
      ...appointmentOverview
      patient {
        ...patientOverview
      }
      provider {
        ...providerOverview
      }
    }
  }
}
    ${AppointmentOverviewFragmentDoc}
${PatientOverviewFragmentDoc}
${ProviderOverviewFragmentDoc}`;
export const ProviderDepartmentAppointmentCountCreatedSubscriptionResultFragmentDoc = gql`
    fragment providerDepartmentAppointmentCountCreatedSubscriptionResult on Subscription {
  providerAppointmentCountCreated(providerId: $providerId) {
    providerDepartmentAppointmentCount {
      ...providerDepartmentAppointmentCount
    }
  }
}
    ${ProviderDepartmentAppointmentCountFragmentDoc}`;
export const ProviderDepartmentAppointmentCountUpdatedSubscriptionResultFragmentDoc = gql`
    fragment providerDepartmentAppointmentCountUpdatedSubscriptionResult on Subscription {
  providerAppointmentCountUpdated(providerId: $providerId) {
    providerDepartmentAppointmentCount {
      ...providerDepartmentAppointmentCount
    }
  }
}
    ${ProviderDepartmentAppointmentCountFragmentDoc}`;
export const ReceptionStatusPracticeFragmentDoc = gql`
    fragment receptionStatusPractice on Practice {
  practiceId
  name
}
    `;
export const ReceptionStatusProviderFragmentDoc = gql`
    fragment receptionStatusProvider on Provider {
  providerId
  displayName
}
    `;
export const ActorDelayConfigsFragmentDoc = gql`
    fragment actorDelayConfigs on Actor {
  actorId
  managerNotifyTimeInReceptionDelays
  managerNotifyTimeInReceptionDelaysMethod
  managerNotifyTimeInReceptionDelaysAfterMinutes
  managerIgnoreTimeInReceptionEarlyCheckIn
  managerNotifyWaitForIntakeDelays
  managerNotifyWaitForIntakeDelaysMethod
  managerNotifyWaitForIntakeDelaysAfterMinutes
  managerNotifyWaitForDoctorDelays
  managerNotifyWaitForDoctorDelaysMethod
  managerNotifyWaitForDoctorDelaysAfterMinutes
}
    `;
export const AdminManagerDetailFragmentDoc = gql`
    fragment adminManagerDetail on Manager {
  ...managerOverview
  eulaTimestamp
  created
  updated
  eulaTimestamp
  tableauManager {
    managerId
    username
    email
  }
  managerPasswordResetTokens {
    nodes {
      ...managerPasswordResetToken
    }
  }
  managerProviderPermissions {
    nodes {
      ...managerProviderPermission
    }
    aggregates {
      distinctCount {
        providerId
        departmentId
      }
    }
  }
}
    ${ManagerOverviewFragmentDoc}
${ManagerPasswordResetTokenFragmentDoc}
${ManagerProviderPermissionFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment pageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PatientAppointmentEstimateFragmentDoc = gql`
    fragment patientAppointmentEstimate on AppointmentEstimate {
  appointmentId
  suggestedArrivalTime
  estIntakeTime
}
    `;
export const PatientAppointmentFragmentDoc = gql`
    fragment patientAppointment on Appointment {
  appointmentId
  timeScheduled
  appointmentEstimate {
    ...patientAppointmentEstimate
  }
}
    ${PatientAppointmentEstimateFragmentDoc}`;
export const PatientDepartmentFragmentDoc = gql`
    fragment patientDepartment on Department {
  departmentId
  name
  publicName
  timezone
  phoneNumber
  address {
    ...address
  }
}
    ${AddressFragmentDoc}`;
export const PatientProviderFragmentDoc = gql`
    fragment patientProvider on Provider {
  providerId
}
    `;
export const PatientPracticeFragmentDoc = gql`
    fragment patientPractice on Practice {
  practiceId
  name
}
    `;
export const PatientPatientFragmentDoc = gql`
    fragment patientPatient on Patient {
  patientId
  fullNameDisplay
  firstName
  middleName
  lastName
}
    `;
export const PatientAppointmentTypeFragmentDoc = gql`
    fragment patientAppointmentType on AppointmentType {
  appointmentTypeId
  name
  nameDisplay
}
    `;
export const DetailedPatientAppointmentFragmentDoc = gql`
    fragment detailedPatientAppointment on Appointment {
  ...patientAppointment
  department {
    ...patientDepartment
  }
  provider {
    ...patientProvider
  }
  practice {
    ...patientPractice
  }
  patient {
    ...patientPatient
  }
  appointmentType {
    ...patientAppointmentType
  }
}
    ${PatientAppointmentFragmentDoc}
${PatientDepartmentFragmentDoc}
${PatientProviderFragmentDoc}
${PatientPracticeFragmentDoc}
${PatientPatientFragmentDoc}
${PatientAppointmentTypeFragmentDoc}`;
export const PatientDetailFragmentDoc = gql`
    fragment patientDetail on Patient {
  ...patientOverview
}
    ${PatientOverviewFragmentDoc}`;
export const SlotOverviewFragmentDoc = gql`
    fragment slotOverview on Slot {
  slotId
  practiceId
  providerId
  renderingProviderId
  departmentId
  appointmentTypeId
  timeScheduled
  duration
  isFrozen
  created
  updated
}
    `;
export const WaitlistOverviewFragmentDoc = gql`
    fragment waitlistOverview on Waitlist {
  waitlistId
  practiceId
  providerId
  departmentId
  appointmentTypeId
  patientId
  priority
  daysOfWeek
  hourFrom
  hourTo
  note
  created
  updated
}
    `;
export const ProviderDashboardWarningsQueryResultFragmentDoc = gql`
    fragment providerDashboardWarningsQueryResult on Query {
  providerDashboardWarnings(
    filter: {or: [{practiceId: {equalTo: $practiceId}}, {practiceId: {isNull: true}}]}
  ) {
    nodes {
      ...providerDashboardWarning
    }
  }
}
    ${ProviderDashboardWarningFragmentDoc}`;
export const AdminCreateAdminMutationDocument = gql`
    mutation AdminCreateAdminMutation($input: CreateAdminInput!) {
  ...adminCreateAdminMutationResult
}
    ${AdminCreateAdminMutationResultFragmentDoc}`;
export const AdminCreateAppointmentCycleConfigMutationDocument = gql`
    mutation AdminCreateAppointmentCycleConfigMutation($input: CreateAppointmentCycleConfigInput!) {
  ...adminCreateAppointmentCycleConfigMutationResult
}
    ${AdminCreateAppointmentCycleConfigMutationResultFragmentDoc}`;
export const AdminCreateAppointmentCycleDefaultValueMutationDocument = gql`
    mutation AdminCreateAppointmentCycleDefaultValueMutation($input: CreateAppointmentCycleDefaultValueInput!) {
  ...adminCreateAppointmentCycleDefaultValueMutationResult
}
    ${AdminCreateAppointmentCycleDefaultValueMutationResultFragmentDoc}`;
export const AdminCreateAppointmentTypeFavoriteMutationDocument = gql`
    mutation AdminCreateAppointmentTypeFavoriteMutation($input: CreateAdminFavoriteAppointmentTypeInput!) {
  ...adminCreateAppointmentTypeFavoriteMutationResult
}
    ${AdminCreateAppointmentTypeFavoriteMutationResultFragmentDoc}`;
export const AdminCreateDepartmentFavoriteMutationDocument = gql`
    mutation AdminCreateDepartmentFavoriteMutation($input: CreateAdminFavoriteDepartmentInput!) {
  ...adminCreateDepartmentFavoriteMutationResult
}
    ${AdminCreateDepartmentFavoriteMutationResultFragmentDoc}`;
export const AdminCreateDepartmentWorkingHourMutationDocument = gql`
    mutation AdminCreateDepartmentWorkingHourMutation($input: CreateDepartmentWorkingHourInput!) {
  ...adminCreateDepartmentWorkingHourMutationResult
}
    ${AdminCreateDepartmentWorkingHourMutationResultFragmentDoc}`;
export const AdminCreateManagerMutationDocument = gql`
    mutation AdminCreateManagerMutation($input: CreateManagerInput!) {
  ...adminCreateManagerMutationResult
}
    ${AdminCreateManagerMutationResultFragmentDoc}`;
export const AdminCreateManagerProviderPermissionMutationDocument = gql`
    mutation AdminCreateManagerProviderPermissionMutation($input: CreateManagerProviderPermissionInput!) {
  ...AdminCreateManagerProviderPermissionMutationResult
}
    ${AdminCreateManagerProviderPermissionMutationResultFragmentDoc}`;
export const AdminCreatePracticeFavoriteMutationDocument = gql`
    mutation AdminCreatePracticeFavoriteMutation($input: CreateAdminFavoritePracticeInput!) {
  ...adminCreatePracticeFavoriteMutationResult
}
    ${AdminCreatePracticeFavoriteMutationResultFragmentDoc}`;
export const AdminCreatePracticeSlugMutationDocument = gql`
    mutation AdminCreatePracticeSlugMutation($input: CreatePracticeSlugInput!) {
  ...adminCreatePracticeSlugMutationResult
}
    ${AdminCreatePracticeSlugMutationResultFragmentDoc}`;
export const AdminCreatePracticeSubscriptionMutationDocument = gql`
    mutation AdminCreatePracticeSubscriptionMutation($input: CreatePracticeSubscriptionInput!) {
  ...adminCreatePracticeSubscriptionMutationResult
}
    ${AdminCreatePracticeSubscriptionMutationResultFragmentDoc}`;
export const AdminCreatePracticeTableauReportMutationDocument = gql`
    mutation AdminCreatePracticeTableauReportMutation($input: CreatePracticeTableauReportInput!) {
  ...adminCreatePracticeTableauReportMutationResult
}
    ${AdminCreatePracticeTableauReportMutationResultFragmentDoc}`;
export const AdminCreateProviderDashboardWarningMutationDocument = gql`
    mutation AdminCreateProviderDashboardWarningMutation($input: CreateProviderDashboardWarningInput!) {
  ...adminCreateProviderDashboardWarningMutationResult
}
    ${AdminCreateProviderDashboardWarningMutationResultFragmentDoc}`;
export const AdminCreateProviderDepartmentOptionMutationDocument = gql`
    mutation AdminCreateProviderDepartmentOptionMutation($input: CreateProviderDepartmentOptionInput!) {
  ...adminCreateProviderDepartmentOptionMutationResult
}
    ${AdminCreateProviderDepartmentOptionMutationResultFragmentDoc}`;
export const AdminCreateProviderDepartmentPaidConversionMutationDocument = gql`
    mutation AdminCreateProviderDepartmentPaidConversionMutation($input: CreateProviderDepartmentPaidConversionInput!) {
  ...adminCreateProviderDepartmentPaidConversionMutationResult
}
    ${AdminCreateProviderDepartmentPaidConversionMutationResultFragmentDoc}`;
export const AdminCreateProviderFavoriteMutationDocument = gql`
    mutation AdminCreateProviderFavoriteMutation($input: CreateAdminFavoriteProviderInput!) {
  ...adminCreateProviderFavoriteMutationResult
}
    ${AdminCreateProviderFavoriteMutationResultFragmentDoc}`;
export const AdminCreateTemplateMutationDocument = gql`
    mutation AdminCreateTemplateMutation($input: CreateTemplateInput!) {
  ...adminCreateTemplateMutationResult
}
    ${AdminCreateTemplateMutationResultFragmentDoc}`;
export const AdminCreateTranslationContentMutationDocument = gql`
    mutation AdminCreateTranslationContentMutation($input: CreateTranslationContentInput!, $translationId: String!) {
  ...adminCreateTranslationContentMutationResult
}
    ${AdminCreateTranslationContentMutationResultFragmentDoc}`;
export const AdminCreateTranslationMutationDocument = gql`
    mutation AdminCreateTranslationMutation($input: CreateTranslationInput!) {
  ...adminCreateTranslationMutationResult
}
    ${AdminCreateTranslationMutationResultFragmentDoc}`;
export const AdminDeleteAppointmentCycleConfigMutationDocument = gql`
    mutation AdminDeleteAppointmentCycleConfigMutation($input: DeleteAppointmentCycleConfigInput!) {
  ...adminDeleteAppointmentCycleConfigMutationResult
}
    ${AdminDeleteAppointmentCycleConfigMutationResultFragmentDoc}`;
export const AdminDeleteAppointmentCycleDefaultValueMutationDocument = gql`
    mutation AdminDeleteAppointmentCycleDefaultValueMutation($input: DeleteAppointmentCycleDefaultValueInput!) {
  ...adminDeleteAppointmentCycleDefaultValueMutationResult
}
    ${AdminDeleteAppointmentCycleDefaultValueMutationResultFragmentDoc}`;
export const AdminDeleteAppointmentTypeFavoriteMutationDocument = gql`
    mutation AdminDeleteAppointmentTypeFavoriteMutation($input: DeleteAdminFavoriteAppointmentTypeByAppointmentTypeIdAndAdminIdInput!) {
  ...adminDeleteAppointmentTypeFavoriteMutationResult
}
    ${AdminDeleteAppointmentTypeFavoriteMutationResultFragmentDoc}`;
export const AdminDeleteDemoDataMutationDocument = gql`
    mutation AdminDeleteDemoDataMutation($input: DeleteDemoDataInput!) {
  ...adminDeleteDemoDataMutationResult
}
    ${AdminDeleteDemoDataMutationResultFragmentDoc}`;
export const AdminDeleteDepartmentFavoriteMutationDocument = gql`
    mutation AdminDeleteDepartmentFavoriteMutation($input: DeleteAdminFavoriteDepartmentByDepartmentIdAndAdminIdInput!) {
  ...adminDeleteDepartmentFavoriteMutationResult
}
    ${AdminDeleteDepartmentFavoriteMutationResultFragmentDoc}`;
export const AdminDeleteDepartmentWorkingHourMutationDocument = gql`
    mutation AdminDeleteDepartmentWorkingHourMutation($input: DeleteDepartmentWorkingHourInput!) {
  ...adminDeleteDepartmentWorkingHourMutationResult
}
    ${AdminDeleteDepartmentWorkingHourMutationResultFragmentDoc}`;
export const AdminDeleteManagerProviderPermissionMutationDocument = gql`
    mutation AdminDeleteManagerProviderPermissionMutation($input: DeleteManagerProviderPermissionInput!) {
  ...adminDeleteManagerProviderPermissionMutationResult
}
    ${AdminDeleteManagerProviderPermissionMutationResultFragmentDoc}`;
export const AdminDeletePracticeFavoriteMutationDocument = gql`
    mutation AdminDeletePracticeFavoriteMutation($input: DeleteAdminFavoritePracticeByPracticeIdAndAdminIdInput!) {
  ...adminDeletePracticeFavoriteMutationResult
}
    ${AdminDeletePracticeFavoriteMutationResultFragmentDoc}`;
export const AdminDeletePracticeTableauReportMutationDocument = gql`
    mutation AdminDeletePracticeTableauReportMutation($input: DeletePracticeTableauReportInput!, $practiceId: String!) {
  ...adminDeletePracticeTableauReportMutationResult
}
    ${AdminDeletePracticeTableauReportMutationResultFragmentDoc}`;
export const AdminDeleteProviderDashboardWarningMutationDocument = gql`
    mutation AdminDeleteProviderDashboardWarningMutation($input: DeleteProviderDashboardWarningInput!) {
  ...adminDeleteProviderDashboardWarningMutationResult
}
    ${AdminDeleteProviderDashboardWarningMutationResultFragmentDoc}`;
export const AdminDeleteProviderDepartmentOptionMutationDocument = gql`
    mutation AdminDeleteProviderDepartmentOptionMutation($input: DeleteProviderDepartmentOptionInput!) {
  ...adminDeleteProviderDepartmentOptionMutationResult
}
    ${AdminDeleteProviderDepartmentOptionMutationResultFragmentDoc}`;
export const AdminDeleteProviderDepartmentPaidConversionMutationDocument = gql`
    mutation AdminDeleteProviderDepartmentPaidConversionMutation($input: DeleteProviderDepartmentPaidConversionInput!) {
  ...adminDeleteProviderDepartmentPaidConversionMutationResult
}
    ${AdminDeleteProviderDepartmentPaidConversionMutationResultFragmentDoc}`;
export const AdminDeleteProviderFavoriteMutationDocument = gql`
    mutation AdminDeleteProviderFavoriteMutation($input: DeleteAdminFavoriteProviderByProviderIdAndAdminIdInput!) {
  ...adminDeleteProviderFavoriteMutationResult
}
    ${AdminDeleteProviderFavoriteMutationResultFragmentDoc}`;
export const AdminDeleteTemplateMutationDocument = gql`
    mutation AdminDeleteTemplateMutation($input: DeleteTemplateInput!) {
  ...adminDeleteTemplateMutationResult
}
    ${AdminDeleteTemplateMutationResultFragmentDoc}`;
export const AdminDeleteTranslationContentMutationDocument = gql`
    mutation AdminDeleteTranslationContentMutation($input: DeleteTranslationContentByTranslationIdAndLanguageIdInput!, $translationId: String!) {
  ...adminDeleteTranslationContentMutationResult
}
    ${AdminDeleteTranslationContentMutationResultFragmentDoc}`;
export const AdminDeleteTranslationMutationDocument = gql`
    mutation AdminDeleteTranslationMutation($input: DeleteTranslationInput!, $translationId: String!) {
  ...adminDeleteTranslationMutationResult
}
    ${AdminDeleteTranslationMutationResultFragmentDoc}`;
export const AdminMakeManagerPasswordResetTokenMutationDocument = gql`
    mutation AdminMakeManagerPasswordResetTokenMutation($managerId: String!) {
  ...adminMakeManagerPasswordResetTokenMutationResult
}
    ${AdminMakeManagerPasswordResetTokenMutationResultFragmentDoc}`;
export const AdminQueueAthenaPracticeRefreshAppointmentTypesMutationDocument = gql`
    mutation AdminQueueAthenaPracticeRefreshAppointmentTypesMutation($input: QueueAthenaPracticeRefreshAppointmentTypesInput!) {
  ...adminQueueAthenaPracticeRefreshAppointmentTypesMutationResult
}
    ${AdminQueueAthenaPracticeRefreshAppointmentTypesMutationResultFragmentDoc}`;
export const AdminQueueAthenaPracticeSetUpMutationDocument = gql`
    mutation AdminQueueAthenaPracticeSetUpMutation($input: QueueAthenaPracticeSetUpInput!) {
  ...adminQueueAthenaPracticeSetUpMutationResult
}
    ${AdminQueueAthenaPracticeSetUpMutationResultFragmentDoc}`;
export const AdminQueueGenerateDemoDataMutationDocument = gql`
    mutation AdminQueueGenerateDemoDataMutation($input: QueueGenerateDemoDataInput!) {
  ...adminQueueGenerateDemoDataMutationResult
}
    ${AdminQueueGenerateDemoDataMutationResultFragmentDoc}`;
export const AdminQueueProviderDayAppointmentFetchingMutationDocument = gql`
    mutation AdminQueueProviderDayAppointmentFetchingMutation($input: QueueProviderDayAppointmentFetchingInput!) {
  ...adminQueueProviderDayAppointmentFetchingMutationResult
}
    ${AdminQueueProviderDayAppointmentFetchingMutationResultFragmentDoc}`;
export const AdminQueueRefreshPracticeAppointmentTypeStatsMutationDocument = gql`
    mutation AdminQueueRefreshPracticeAppointmentTypeStatsMutation($input: QueueRefreshPracticeAppointmentTypeStatsInput!) {
  ...adminQueueRefreshPracticeAppointmentTypeStatsMutationResult
}
    ${AdminQueueRefreshPracticeAppointmentTypeStatsMutationResultFragmentDoc}`;
export const AdminQueueRestorePracticeDayFromS3MutationDocument = gql`
    mutation AdminQueueRestorePracticeDayFromS3Mutation($input: QueueRestorePracticeDayFromS3Input!) {
  ...adminQueueRestorePracticeDayFromS3MutationResult
}
    ${AdminQueueRestorePracticeDayFromS3MutationResultFragmentDoc}`;
export const AdminSetAdminPasswordMutationDocument = gql`
    mutation AdminSetAdminPasswordMutation($input: SetAdminPasswordInput!) {
  ...adminSetAdminPasswordMutationResult
}
    ${AdminSetAdminPasswordMutationResultFragmentDoc}`;
export const AdminUpdateActorAdminDashboardSettingsMutationDocument = gql`
    mutation AdminUpdateActorAdminDashboardSettingsMutation($actorId: Int!, $settings: JSON!) {
  ...adminUpdateActorAdminDashboardSettingsMutationResult
}
    ${AdminUpdateActorAdminDashboardSettingsMutationResultFragmentDoc}`;
export const AdminUpdateActorMutationDocument = gql`
    mutation AdminUpdateActorMutation($actorId: Int!, $patch: ActorPatch!) {
  ...adminUpdateActorMutationResult
}
    ${AdminUpdateActorMutationResultFragmentDoc}`;
export const AdminUpdateAdminMutationDocument = gql`
    mutation AdminUpdateAdminMutation($input: UpdateAdminInput!) {
  ...adminUpdateAdminMutationResult
}
    ${AdminUpdateAdminMutationResultFragmentDoc}`;
export const AdminUpdateAppointmentCycleConfigMutationDocument = gql`
    mutation AdminUpdateAppointmentCycleConfigMutation($input: UpdateAppointmentCycleConfigInput!) {
  ...adminUpdateAppointmentCycleConfigMutationResult
}
    ${AdminUpdateAppointmentCycleConfigMutationResultFragmentDoc}`;
export const AdminUpdateAppointmentCycleDefaultValueMutationDocument = gql`
    mutation AdminUpdateAppointmentCycleDefaultValueMutation($input: UpdateAppointmentCycleDefaultValueInput!) {
  ...adminUpdateAppointmentCycleDefaultValueMutationResult
}
    ${AdminUpdateAppointmentCycleDefaultValueMutationResultFragmentDoc}`;
export const AdminUpdateAppointmentTypeMutationDocument = gql`
    mutation AdminUpdateAppointmentTypeMutation($appointmentTypeId: String!, $patch: AppointmentTypePatch!) {
  ...adminUpdateAppointmentTypeMutationResult
}
    ${AdminUpdateAppointmentTypeMutationResultFragmentDoc}`;
export const AdminUpdateDepartmentMutationDocument = gql`
    mutation AdminUpdateDepartmentMutation($departmentId: String!, $patch: DepartmentPatch!) {
  ...adminUpdateDepartmentMutationResult
}
    ${AdminUpdateDepartmentMutationResultFragmentDoc}`;
export const AdminUpdateDepartmentWorkingHourMutationDocument = gql`
    mutation AdminUpdateDepartmentWorkingHourMutation($input: UpdateDepartmentWorkingHourInput!) {
  ...adminUpdateDepartmentWorkingHourMutationResult
}
    ${AdminUpdateDepartmentWorkingHourMutationResultFragmentDoc}`;
export const AdminUpdatePracticeMutationDocument = gql`
    mutation AdminUpdatePracticeMutation($practiceId: String!, $patch: PracticePatch!) {
  ...adminUpdatePracticeMutationResult
}
    ${AdminUpdatePracticeMutationResultFragmentDoc}`;
export const AdminUpdatePracticePatientLocationMutationDocument = gql`
    mutation AdminUpdatePracticePatientLocationMutation($practicePatientLocationId: Int!, $patch: PracticePatientLocationPatch!) {
  ...adminUpdatePracticePatientLocationMutationResult
}
    ${AdminUpdatePracticePatientLocationMutationResultFragmentDoc}`;
export const AdminUpdatePracticeSlugMutationDocument = gql`
    mutation AdminUpdatePracticeSlugMutation($practiceSlugId: Int!, $patch: PracticeSlugPatch!) {
  ...adminUpdatePracticeSlugMutationResult
}
    ${AdminUpdatePracticeSlugMutationResultFragmentDoc}`;
export const AdminUpdatePracticeTableauReportMutationDocument = gql`
    mutation AdminUpdatePracticeTableauReportMutation($practiceTableauReportId: Int!, $patch: PracticeTableauReportPatch!) {
  updatePracticeTableauReport(
    input: {practiceTableauReportId: $practiceTableauReportId, patch: $patch}
  ) {
    practiceTableauReport {
      ...practiceTableauReport
      practice {
        practiceId
        practiceTableauReports {
          nodes {
            ...practiceTableauReport
          }
        }
      }
    }
  }
}
    ${PracticeTableauReportFragmentDoc}`;
export const AdminUpdateProviderDashboardWarningMutationDocument = gql`
    mutation AdminUpdateProviderDashboardWarningMutation($input: UpdateProviderDashboardWarningInput!) {
  ...adminUpdateProviderDashboardWarningMutationResult
}
    ${AdminUpdateProviderDashboardWarningMutationResultFragmentDoc}`;
export const AdminUpdateProviderDepartmentOptionMutationDocument = gql`
    mutation AdminUpdateProviderDepartmentOptionMutation($input: UpdateProviderDepartmentOptionInput!) {
  ...adminUpdateProviderDepartmentOptionMutationResult
}
    ${AdminUpdateProviderDepartmentOptionMutationResultFragmentDoc}`;
export const AdminUpdateProviderDepartmentPaidConversionMutationDocument = gql`
    mutation AdminUpdateProviderDepartmentPaidConversionMutation($input: UpdateProviderDepartmentPaidConversionInput!) {
  ...adminUpdateProviderDepartmentPaidConversionMutationResult
}
    ${AdminUpdateProviderDepartmentPaidConversionMutationResultFragmentDoc}`;
export const AdminUpdateProviderMutationDocument = gql`
    mutation AdminUpdateProviderMutation($providerId: String!, $patch: ProviderPatch!) {
  ...adminUpdateProviderMutationResult
}
    ${AdminUpdateProviderMutationResultFragmentDoc}`;
export const AdminUpdateTemplateMutationDocument = gql`
    mutation AdminUpdateTemplateMutation($input: UpdateTemplateInput!) {
  ...adminUpdateTemplateMutationResult
}
    ${AdminUpdateTemplateMutationResultFragmentDoc}`;
export const AdminUpdateTranslationContentMutationDocument = gql`
    mutation AdminUpdateTranslationContentMutation($input: UpdateTranslationContentByTranslationIdAndLanguageIdInput!, $translationId: String!) {
  ...adminUpdateTranslationContentMutationResult
}
    ${AdminUpdateTranslationContentMutationResultFragmentDoc}`;
export const AdminUpdateTranslationMutationDocument = gql`
    mutation AdminUpdateTranslationMutation($input: UpdateTranslationInput!) {
  ...adminUpdateTranslationMutationResult
}
    ${AdminUpdateTranslationMutationResultFragmentDoc}`;
export const AdminAdminDetailQueryDocument = gql`
    query AdminAdminDetailQuery($adminId: String!) {
  ...adminAdminDetailQueryResult
}
    ${AdminAdminDetailQueryResultFragmentDoc}`;
export const AdminAdminsQueryDocument = gql`
    query AdminAdminsQuery {
  ...adminAdminsQueryResult
}
    ${AdminAdminsQueryResultFragmentDoc}`;
export const AdminAppointmentDayQueryDocument = gql`
    query AdminAppointmentDayQuery($practiceId: String!, $providerIds: [String!]!, $dayStart: Datetime!, $dayEnd: Datetime!, $filterStatuses: [String!]!) {
  ...adminAppointmentDayQueryResult
}
    ${AdminAppointmentDayQueryResultFragmentDoc}`;
export const AdminAppointmentDayV2QueryDocument = gql`
    query AdminAppointmentDayV2Query($practiceId: String!, $providerIds: [String!]!, $dateScheduled: String!) {
  ...adminAppointmentDayV2QueryResult
}
    ${AdminAppointmentDayV2QueryResultFragmentDoc}`;
export const AdminAppointmentDurationLogRatiosCompiledQueryDocument = gql`
    query AdminAppointmentDurationLogRatiosCompiledQuery($filter: AppointmentDurationLogRatiosCompiledFilter) {
  ...adminAppointmentDurationLogRatiosCompiledQueryResult
}
    ${AdminAppointmentDurationLogRatiosCompiledQueryResultFragmentDoc}`;
export const AdminAppointmentTypeDetailQueryDocument = gql`
    query AdminAppointmentTypeDetailQuery($appointmentTypeId: String!) {
  ...adminAppointmentTypeDetailQueryResult
}
    ${AdminAppointmentTypeDetailQueryResultFragmentDoc}`;
export const AdminAppointmentTypeProviderStatQueryDocument = gql`
    query AdminAppointmentTypeProviderStatQuery($appointmentTypeId: String!, $providerId: String!) {
  ...adminAppointmentTypeProviderStatQueryResult
}
    ${AdminAppointmentTypeProviderStatQueryResultFragmentDoc}`;
export const AdminBaseQueryDocument = gql`
    query AdminBaseQuery {
  ...adminBaseQueryResult
}
    ${AdminBaseQueryResultFragmentDoc}`;
export const AdminGetCompiledProviderDepartmentOptionQueryDocument = gql`
    query AdminGetCompiledProviderDepartmentOptionQuery($appointmentTypeId: String, $providerId: String, $departmentId: String, $practiceId: String!) {
  ...adminGetCompiledProviderDepartmentOptionQueryResult
}
    ${AdminGetCompiledProviderDepartmentOptionQueryResultFragmentDoc}`;
export const AdminGooglePlaceByIdQueryDocument = gql`
    query AdminGooglePlaceByIdQuery($placeId: String!) {
  ...adminGooglePlaceByIdQueryResult
}
    ${AdminGooglePlaceByIdQueryResultFragmentDoc}`;
export const AdminManagerPasswordResetTokensCardQueryDocument = gql`
    query AdminManagerPasswordResetTokensCardQuery($managerId: String!) {
  ...adminManagerPasswordResetTokensCardQueryResult
}
    ${AdminManagerPasswordResetTokensCardQueryResultFragmentDoc}`;
export const AdminPracticeBaseProvidersQueryDocument = gql`
    query AdminPracticeBaseProvidersQuery($practiceId: String!, $dayStart: Date!) {
  ...adminPracticeBaseProvidersQueryResult
}
    ${AdminPracticeBaseProvidersQueryResultFragmentDoc}`;
export const AdminPracticeBaseQueryDocument = gql`
    query AdminPracticeBaseQuery($practiceId: String!, $dayStart: Date!) {
  ...adminPracticeBaseQueryResult
}
    ${AdminPracticeBaseQueryResultFragmentDoc}`;
export const AdminPracticeManagerDetailQueryDocument = gql`
    query AdminPracticeManagerDetailQuery($managerId: String!) {
  ...adminPracticeManagerDetailQueryResult
}
    ${AdminPracticeManagerDetailQueryResultFragmentDoc}`;
export const AdminPracticeManagersQueryDocument = gql`
    query AdminPracticeManagersQuery($practiceId: String!) {
  ...adminPracticeManagersQueryResult
}
    ${AdminPracticeManagersQueryResultFragmentDoc}`;
export const AdminPracticePatientLocationsQueryDocument = gql`
    query AdminPracticePatientLocationsQuery($practiceId: String!) {
  ...adminPracticePatientLocationsQueryResult
}
    ${AdminPracticePatientLocationsQueryResultFragmentDoc}`;
export const AdminPracticeProviderDepartmentOptionsQueryDocument = gql`
    query AdminPracticeProviderDepartmentOptionsQuery($practiceId: String!) {
  ...adminPracticeProviderDepartmentOptionsQueryResult
}
    ${AdminPracticeProviderDepartmentOptionsQueryResultFragmentDoc}`;
export const AdminProviderAppointmentDayFetchingQueryDocument = gql`
    query AdminProviderAppointmentDayFetchingQuery($practiceId: String!) {
  ...adminProviderAppointmentDayFetchingQueryResult
}
    ${AdminProviderAppointmentDayFetchingQueryResultFragmentDoc}`;
export const AdminProviderDashboardWarningsQueryDocument = gql`
    query AdminProviderDashboardWarningsQuery {
  ...adminProviderDashboardWarningsQueryResult
}
    ${AdminProviderDashboardWarningsQueryResultFragmentDoc}`;
export const AdminProviderDayReportQueryDocument = gql`
    query AdminProviderDayReportQuery($providerId: String!, $startDate: Datetime!, $endDate: Datetime!) {
  ...adminProviderDayReportQueryResult
}
    ${AdminProviderDayReportQueryResultFragmentDoc}`;
export const AdminProviderDetailQueryDocument = gql`
    query AdminProviderDetailQuery($providerId: String!, $dayStart: Date!) {
  ...adminProviderDetailQueryResult
}
    ${AdminProviderDetailQueryResultFragmentDoc}`;
export const AdminSearchGooglePlaceQueryDocument = gql`
    query AdminSearchGooglePlaceQuery($search: String!, $type: GooglePlaceSearchType) {
  searchGooglePlace(search: $search, type: $type) {
    candidates {
      place_id
    }
  }
}
    `;
export const AdminUpdateAppointmentMutationDocument = gql`
    mutation AdminUpdateAppointmentMutation($appointmentId: String!, $patch: AppointmentPatch!, $providerId: String!) {
  ...adminUpdateAppointmentMutationResult
}
    ${AdminUpdateAppointmentMutationResultFragmentDoc}`;
export const AuthTableauMutationDocument = gql`
    mutation AuthTableauMutation($email: String!) {
  ...authTableauMutationResult
}
    ${AuthTableauMutationResultFragmentDoc}`;
export const CreateProviderDepartmentAutoTextPausesMutationDocument = gql`
    mutation CreateProviderDepartmentAutoTextPausesMutation($practiceId: String!, $providerIds: [String]!) {
  ...createProviderDepartmentAutoTextPausesMutationResult
}
    ${CreateProviderDepartmentAutoTextPausesMutationResultFragmentDoc}`;
export const PauseAppointmentMutationDocument = gql`
    mutation PauseAppointmentMutation($appointmentId: String!, $providerId: String!, $pausedAt: Datetime!) {
  ...pauseAppointmentMutationResult
}
    ${PauseAppointmentMutationResultFragmentDoc}`;
export const QueueAppointmentSmsMutationDocument = gql`
    mutation QueueAppointmentSmsMutation($input: QueueSendSmsInput!) {
  ...queueAppointmentSmsMutationResult
}
    ${QueueAppointmentSmsMutationResultFragmentDoc}`;
export const QueueDemoAppointmentTextMutationDocument = gql`
    mutation QueueDemoAppointmentTextMutation($input: QueueDemoAppointmentTextInput!) {
  ...queueDemoAppointmentTextMutationResult
}
    ${QueueDemoAppointmentTextMutationResultFragmentDoc}`;
export const SetAppointmentDoneMutationDocument = gql`
    mutation SetAppointmentDoneMutation($appointmentId: String!, $timeEnd: Datetime!, $providerId: String!) {
  ...setAppointmentDoneMutationResult
}
    ${SetAppointmentDoneMutationResultFragmentDoc}`;
export const SetAppointmentReadyForCheckInMutationDocument = gql`
    mutation SetAppointmentReadyForCheckInMutation($input: QueueReadyForCheckInInput!, $appointmentId: String!, $roomReadyButtonClickedAt: Datetime!) {
  ...setAppointmentReadyForCheckInMutationResult
}
    ${SetAppointmentReadyForCheckInMutationResultFragmentDoc}`;
export const UnpauseAppointmentMutationDocument = gql`
    mutation UnpauseAppointmentMutation($appointmentId: String!, $providerId: String!) {
  ...unpauseAppointmentMutationResult
}
    ${UnpauseAppointmentMutationResultFragmentDoc}`;
export const UpdateActorManagerDashboardSettingsMutationDocument = gql`
    mutation UpdateActorManagerDashboardSettingsMutation($actorId: Int!, $settings: JSON!) {
  ...updateActorManagerDashboardSettingsMutationResult
}
    ${UpdateActorManagerDashboardSettingsMutationResultFragmentDoc}`;
export const UpdateManagerMutationDocument = gql`
    mutation UpdateManagerMutation($managerId: String!, $patch: ManagerPatch!) {
  ...updateManagerMutationResult
}
    ${UpdateManagerMutationResultFragmentDoc}`;
export const AdminFavoritesQueryDocument = gql`
    query AdminFavoritesQuery($adminId: String!) {
  ...adminFavoritesQueryResult
}
    ${AdminFavoritesQueryResultFragmentDoc}`;
export const AdminSmsTranslationDetailQueryDocument = gql`
    query AdminSmsTranslationDetailQuery($practiceId: String!, $translationId: String!) {
  ...adminSmsTranslationDetailQueryResult
}
    ${AdminSmsTranslationDetailQueryResultFragmentDoc}`;
export const AllLanguagesQueryDocument = gql`
    query AllLanguagesQuery {
  ...allLanguagesQueryResult
}
    ${AllLanguagesQueryResultFragmentDoc}`;
export const AppointmentDetailQueryDocument = gql`
    query AppointmentDetailQuery($appointmentId: String!) {
  ...appointmentDetailQueryResult
}
    ${AppointmentDetailQueryResultFragmentDoc}`;
export const AppointmentTypeOverviewSetQueryDocument = gql`
    query AppointmentTypeOverviewSetQuery($practiceId: String!, $appointmentTypeIds: [String!]!) {
  ...appointmentTypeOverviewSetQueryResult
}
    ${AppointmentTypeOverviewSetQueryResultFragmentDoc}`;
export const DepartmentDetailQueryDocument = gql`
    query DepartmentDetailQuery($departmentId: String!) {
  ...departmentDetailQueryResult
}
    ${DepartmentDetailQueryResultFragmentDoc}`;
export const DepartmentOverviewSetQueryDocument = gql`
    query DepartmentOverviewSetQuery($practiceId: String!, $departmentIds: [String!]!) {
  ...departmentOverviewSetQueryResult
}
    ${DepartmentOverviewSetQueryResultFragmentDoc}`;
export const EnabledLanguagesQueryDocument = gql`
    query EnabledLanguagesQuery {
  ...enabledLanguagesQueryResult
}
    ${EnabledLanguagesQueryResultFragmentDoc}`;
export const GlobalTemplatesQueryDocument = gql`
    query GlobalTemplatesQuery {
  ...globalTemplatesQueryResult
}
    ${GlobalTemplatesQueryResultFragmentDoc}`;
export const GlobalTranslationsQueryDocument = gql`
    query GlobalTranslationsQuery {
  ...globalTranslationsQueryResult
}
    ${GlobalTranslationsQueryResultFragmentDoc}`;
export const ManagerAdminBaseQueryDocument = gql`
    query ManagerAdminBaseQuery($adminId: String!) {
  ...managerAdminBaseQueryResult
}
    ${ManagerAdminBaseQueryResultFragmentDoc}`;
export const ManagerAppointmentDayQueryDocument = gql`
    query ManagerAppointmentDayQuery($practiceId: String!, $providerIds: [String!]!, $dayStart: Datetime!, $dayEnd: Datetime!, $filterStatuses: [String!]!, $managerId: String) {
  ...managerAppointmentDayQueryResult
}
    ${ManagerAppointmentDayQueryResultFragmentDoc}`;
export const ManagerBaseQueryDocument = gql`
    query ManagerBaseQuery {
  ...managerBaseQueryResult
}
    ${ManagerBaseQueryResultFragmentDoc}`;
export const ManagerBillingInfoQueryDocument = gql`
    query ManagerBillingInfoQuery($practiceId: String!) {
  ...managerBillingInfoQueryResult
}
    ${ManagerBillingInfoQueryResultFragmentDoc}`;
export const ManagerCancelledAppointmentDayQueryDocument = gql`
    query ManagerCancelledAppointmentDayQuery($practiceId: String!, $providerIds: [String!]!, $dayStart: Datetime!, $dayEnd: Datetime!, $managerId: String) {
  ...managerCancelledAppointmentDayQueryResult
}
    ${ManagerCancelledAppointmentDayQueryResultFragmentDoc}`;
export const ManagerCancelledAppointmentDayV2QueryDocument = gql`
    query ManagerCancelledAppointmentDayV2Query($practiceId: String!, $providerIds: [String!]!, $dateStart: Date!, $dateEnd: Date!, $canceledWithinHours: Float!, $managerId: String) {
  ...managerCancelledAppointmentDayV2QueryResult
}
    ${ManagerCancelledAppointmentDayV2QueryResultFragmentDoc}`;
export const ManagerOverviewQueryDocument = gql`
    query ManagerOverviewQuery($managerId: String!) {
  ...managerOverviewQueryResult
}
    ${ManagerOverviewQueryResultFragmentDoc}`;
export const ManagerPracticeBaseQueryDocument = gql`
    query ManagerPracticeBaseQuery($practiceId: String!, $dayStart: Date!) {
  ...managerPracticeBaseQueryResult
}
    ${ManagerPracticeBaseQueryResultFragmentDoc}`;
export const ManagerPracticeManagersQueryDocument = gql`
    query ManagerPracticeManagersQuery($practiceId: String!) {
  ...managerPracticeManagersQueryResult
}
    ${ManagerPracticeManagersQueryResultFragmentDoc}`;
export const ManagerPracticeProviderCountsQueryDocument = gql`
    query ManagerPracticeProviderCountsQuery($practiceId: String!, $providerIds: [String!]!, $dayStart: Date!) {
  ...managerPracticeProviderCountsQueryResult
}
    ${ManagerPracticeProviderCountsQueryResultFragmentDoc}`;
export const PracticeAppointmentCycleConfigsQueryDocument = gql`
    query PracticeAppointmentCycleConfigsQuery($practiceId: String!) {
  ...practiceAppointmentCycleConfigsQueryResult
}
    ${PracticeAppointmentCycleConfigsQueryResultFragmentDoc}`;
export const PracticeAppointmentCycleDefaultValuesQueryDocument = gql`
    query PracticeAppointmentCycleDefaultValuesQuery($practiceId: String!) {
  ...practiceAppointmentCycleDefaultValuesQueryResult
}
    ${PracticeAppointmentCycleDefaultValuesQueryResultFragmentDoc}`;
export const PracticeOverviewQueryDocument = gql`
    query PracticeOverviewQuery($practiceId: String!) {
  ...practiceOverviewQueryResult
}
    ${PracticeOverviewQueryResultFragmentDoc}`;
export const PracticeReportsQueryDocument = gql`
    query PracticeReportsQuery($practiceId: String!) {
  practice(practiceId: $practiceId) {
    practiceId
    practiceTableauReports {
      totalCount
      nodes {
        ...practiceTableauReport
      }
    }
  }
}
    ${PracticeTableauReportFragmentDoc}`;
export const PracticeTemplatesQueryDocument = gql`
    query PracticeTemplatesQuery($practiceId: String!) {
  ...practiceTemplatesQueryResult
}
    ${PracticeTemplatesQueryResultFragmentDoc}`;
export const PracticeTranslationsQueryDocument = gql`
    query PracticeTranslationsQuery($practiceId: String!) {
  ...practiceTranslationsQueryResult
}
    ${PracticeTranslationsQueryResultFragmentDoc}`;
export const ProviderAppointmentCountsQueryDocument = gql`
    query ProviderAppointmentCountsQuery($providerId: String!, $dayStart: Date!) {
  ...providerAppointmentCountsQueryResult
}
    ${ProviderAppointmentCountsQueryResultFragmentDoc}`;
export const ProviderDayAppointmentsV2QueryDocument = gql`
    query ProviderDayAppointmentsV2Query($practiceId: String!, $providerIds: [String!]!, $dateScheduled: String!, $managerId: String) {
  ...providerDayAppointmentsV2QueryResult
}
    ${ProviderDayAppointmentsV2QueryResultFragmentDoc}`;
export const ProviderDepartmentAppointmentCountsQueryDocument = gql`
    query ProviderDepartmentAppointmentCountsQuery($practiceId: String!) {
  ...providerDepartmentAppointmentCountsQueryResult
}
    ${ProviderDepartmentAppointmentCountsQueryResultFragmentDoc}`;
export const ProviderOverviewSetQueryDocument = gql`
    query ProviderOverviewSetQuery($practiceId: String!, $providerIds: [String!]!) {
  ...providerOverviewSetQueryResult
}
    ${ProviderOverviewSetQueryResultFragmentDoc}`;
export const TranslationDetailQueryDocument = gql`
    query TranslationDetailQuery($translationId: String!) {
  ...translationDetailQueryResult
}
    ${TranslationDetailQueryResultFragmentDoc}`;
export const AppointmentCheckInsSubscriptionDocument = gql`
    subscription AppointmentCheckInsSubscription($providerId: String!) {
  ...appointmentCheckInsSubscriptionResult
}
    ${AppointmentCheckInsSubscriptionResultFragmentDoc}`;
export const AppointmentEstimateCreatedSubscriptionDocument = gql`
    subscription AppointmentEstimateCreatedSubscription($providerId: String!) {
  ...appointmentEstimateCreatedSubscriptionResult
}
    ${AppointmentEstimateCreatedSubscriptionResultFragmentDoc}`;
export const AppointmentEstimateUpdatesSubscriptionDocument = gql`
    subscription AppointmentEstimateUpdatesSubscription($providerId: String!) {
  ...appointmentEstimateUpdatesSubscriptionResult
}
    ${AppointmentEstimateUpdatesSubscriptionResultFragmentDoc}`;
export const AppointmentUpdatesSubscriptionDocument = gql`
    subscription AppointmentUpdatesSubscription($providerId: String!) {
  ...appointmentUpdatesSubscriptionResult
}
    ${AppointmentUpdatesSubscriptionResultFragmentDoc}`;
export const GlobalProviderDashboardWarningNotifierSubscriptionDocument = gql`
    subscription GlobalProviderDashboardWarningNotifierSubscription {
  ...globalProviderDashboardWarningNotifierSubscriptionResult
}
    ${GlobalProviderDashboardWarningNotifierSubscriptionResultFragmentDoc}`;
export const PracticeProviderDashboardWarningNotifierSubscriptionDocument = gql`
    subscription PracticeProviderDashboardWarningNotifierSubscription {
  ...practiceProviderDashboardWarningNotifierSubscriptionResult
}
    ${PracticeProviderDashboardWarningNotifierSubscriptionResultFragmentDoc}`;
export const ProviderAppointmentCancelledSubscriptionDocument = gql`
    subscription ProviderAppointmentCancelledSubscription($providerId: String!) {
  ...providerAppointmentCancelledSubscriptionResult
}
    ${ProviderAppointmentCancelledSubscriptionResultFragmentDoc}`;
export const ProviderAppointmentCreatedSubscriptionDocument = gql`
    subscription ProviderAppointmentCreatedSubscription($providerId: String!) {
  ...providerAppointmentCreatedSubscriptionResult
}
    ${ProviderAppointmentCreatedSubscriptionResultFragmentDoc}`;
export const ProviderAppointmentExamDelaysSubscriptionDocument = gql`
    subscription ProviderAppointmentExamDelaysSubscription($providerId: String!) {
  ...providerAppointmentExamDelaysSubscriptionResult
}
    ${ProviderAppointmentExamDelaysSubscriptionResultFragmentDoc}`;
export const ProviderAppointmentIntakeDelaysSubscriptionDocument = gql`
    subscription ProviderAppointmentIntakeDelaysSubscription($providerId: String!) {
  ...providerAppointmentIntakeDelaysSubscriptionResult
}
    ${ProviderAppointmentIntakeDelaysSubscriptionResultFragmentDoc}`;
export const ProviderDepartmentAppointmentCountCreatedSubscriptionDocument = gql`
    subscription ProviderDepartmentAppointmentCountCreatedSubscription($providerId: String!) {
  ...providerDepartmentAppointmentCountCreatedSubscriptionResult
}
    ${ProviderDepartmentAppointmentCountCreatedSubscriptionResultFragmentDoc}`;
export const ProviderDepartmentAppointmentCountUpdatedSubscriptionDocument = gql`
    subscription ProviderDepartmentAppointmentCountUpdatedSubscription($providerId: String!) {
  ...providerDepartmentAppointmentCountUpdatedSubscriptionResult
}
    ${ProviderDepartmentAppointmentCountUpdatedSubscriptionResultFragmentDoc}`;
export const ProviderDashboardWarningsQueryDocument = gql`
    query ProviderDashboardWarningsQuery($practiceId: String!) {
  ...providerDashboardWarningsQueryResult
}
    ${ProviderDashboardWarningsQueryResultFragmentDoc}`;